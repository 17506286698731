import { useNavigate, useParams } from "react-router-dom";
import useAuthor from "../../utils/hooks/useAuthor";
import * as S from "./CreatorPage.style";
import useAuthorContent from "../../utils/hooks/useAuthorContent";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../utils/hooks/useAuth";

export default function Creator() {
  const { authorId } = useParams<{ authorId: string }>();
  const { author, loading } = useAuthor(authorId!);
  const navigate = useNavigate();
  const { insights, articles, contentLoading } = useAuthorContent(authorId!);
  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanel.track("Pageview_Author", {
      user: user ? "true" : "false",
      authorId: author?.author_id,
      authorName: author?.author_name,
    });
  }, []);

  return (
    <S.Section>
      {loading || contentLoading ? (
        <S.SkeletonWrapper>
          <S.SkeletonAuthorWrapper>
            <S.SkeletonAuthorImg />
            <S.SkeletonAuthorName />
            <S.SkeletonAuthorCareer />
            <S.SkeletonAuthorProfile />
          </S.SkeletonAuthorWrapper>
          <S.SkeletonHeadingContainer>
            <S.SkeletonHeading />
          </S.SkeletonHeadingContainer>
          <S.SkeletonContentWrapper>
            {[...Array(3)].map((_, index) => (
              <S.SkeletonInterviewCard key={index}>
                <S.SkeletonInterviewImg />
                <S.SkeletonInterviewCategory />
                <S.SkeletonInterviewTitle />
                <S.SkeletonInterviewPreview />
              </S.SkeletonInterviewCard>
            ))}
          </S.SkeletonContentWrapper>
        </S.SkeletonWrapper>
      ) : (
        <>
          {author && (
            <>
              <S.AuthorWrapper>
                <S.InterviewAuthorImg>
                  <img src={author.author_image} alt="크리에이터 이미지" />
                </S.InterviewAuthorImg>
                <S.InterviewAuthorName>
                  {author.author_name}
                </S.InterviewAuthorName>
                <S.InterviewAuthorCareer>
                  {author.company} · {author.experience}
                </S.InterviewAuthorCareer>
                <S.InterviewAuthorProfile>
                  {author.author_profile}
                </S.InterviewAuthorProfile>
              </S.AuthorWrapper>
              <S.HeadingContainer>
                <S.Heading>{author.author_name}님이 작성한 글</S.Heading>
              </S.HeadingContainer>
              <S.ContentWrapper>
                {insights.map((insight) => (
                  <S.InterviewCard
                    key={insight.insight_id}
                    onClick={() => {
                      navigate(`/insight/${insight.insight_id}`, {
                        state: { insight },
                      });
                    }}
                  >
                    <S.InterviewImg>
                      <img src={insight.image} alt="인터뷰 이미지" />
                    </S.InterviewImg>
                    <S.InterviewCategory>
                      <span>{insight.category}</span>
                    </S.InterviewCategory>
                    <S.InterviewTitle>{insight.title}</S.InterviewTitle>
                    <S.InterviewPreview>{insight.preview}</S.InterviewPreview>
                  </S.InterviewCard>
                ))}
                {articles.map((article) => (
                  <S.InterviewCard
                    key={article.article_id}
                    onClick={() => {
                      navigate(`/article/${article.article_id}`, {
                        state: { article },
                      });
                    }}
                  >
                    <S.InterviewImg>
                      <img src={article.image} alt="인터뷰 이미지" />
                    </S.InterviewImg>
                    <S.InterviewCategory>
                      <span>{article.category}</span>
                    </S.InterviewCategory>
                    <S.InterviewTitle>{article.title}</S.InterviewTitle>
                    <S.InterviewPreview>{article.preview}</S.InterviewPreview>
                  </S.InterviewCard>
                ))}
              </S.ContentWrapper>
            </>
          )}
        </>
      )}
    </S.Section>
  );
}
