import { ReactComponent as LikeIcon } from '../../assets/likeIcon.svg';
import { ReactComponent as UnlikeIcon } from '../../assets/unlikeIcon.svg';
import styled from 'styled-components';

interface LikeButtonProps {
  isLiked: boolean;
  likeCount: number;
  onLikeToggle: (event: React.MouseEvent<HTMLButtonElement>) => void; // 타입을 유지
}

const Button = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 26px;
    height: 26px;
  }

  span {
    margin-left: 8px;
    font-size: 14px;
    color: #000;
  }
`;

const LikeButton: React.FC<LikeButtonProps> = ({ isLiked, likeCount, onLikeToggle }) => {

  return (
    <Button type="button" onClick={onLikeToggle}>
      {isLiked ? <LikeIcon /> : <UnlikeIcon /> }
      <span>{likeCount}명이 좋아합니다</span>
    </Button>
  );
};

export default LikeButton;