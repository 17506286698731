import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./ChatCard.style";
import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { Chat } from "../../utils/hooks/useChatList";
import { ChatContent } from "../../views/ChatPage/ChatContent";
import { managementClient } from "../../utils/managementClient";
import { useAuth } from "../../utils/hooks/useAuth";

interface ChatCardProps {
  ChatContent: Chat;
}

function ChatCard({ ChatContent: chat }: ChatCardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applyCount, setApplyCount] = useState(chat.apply.length);
  const [isLiked, setIsLiked] = useState(false);
  const { user } = useAuth();

  // useEffect(() => {
  //   const fetchChatStatus = async () => {
  //     try {
  //       const space = await managementClient.getSpace(
  //         process.env.REACT_APP_CONTENTFUL_SPACE!
  //       );
  //       const environment = await space.getEnvironment("master");
  //       const chatEntry = await environment.getEntry(chat.entry_id);
  //       const applyList = chatEntry.fields.apply?.["en-US"] || [];

  //       setApplyCount(applyList.length);
  //       if (user) {
  //         setIsLiked(applyList.includes(user?.id.toString()));
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch chat status:", error);
  //     }
  //   };

  //   fetchChatStatus();
  // }, [chat.chatId, location.state]);

  const openModal = () => {
    navigate(`/chat/${chat.chatId}`, {
      state: { openModal: true, chat },
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  const handleApplyUpdate = (updatedCount: number, likedStatus: boolean) => {
    setApplyCount(updatedCount);
    setIsLiked(likedStatus);
  };

  return (
    <>
      <S.Card onClick={openModal}>
        <S.Thumbnail>
          <img src={chat.image} alt="회사 사진" />
        </S.Thumbnail>
        <S.TitleWrapper>
          <S.Title>{chat.title}</S.Title>
          <S.Status>{chat.status}</S.Status>
          <S.Apply>{applyCount}명이 신청했어요.</S.Apply>
        </S.TitleWrapper>
      </S.Card>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <ChatContent
            Chat={chat}
            onClose={closeModal}
            onApplyUpdate={handleApplyUpdate}
            // initialApplyCount={applyCount}
            // initialIsLiked={isLiked}
          />
        </Modal>
      )}
    </>
  );
}

export default ChatCard;
