import styled, { keyframes } from "styled-components";
import { onLarge } from "../../../styles/mediaQueries";

export const Section = styled.section`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 10px solid #f5f6fa;
`;

export const ArticleSeciton = styled.section`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const RightArrow = styled.div`
  cursor: pointer;
  img {
  }
`;

export const Title = styled.h2`
  color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  width: 324px;
  em {
    color: #3984f4;
    font-style: normal;
  }
`;

export const Desc = styled.p`
  color: #8f9094;
  font-size: 14px;
  font-weight: 500;
  color: #8f9094;
  line-height: 160%;
  width: 324px;
  text-align: left;
`;

export const ArticleCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  margin-bottom: 50px;
  /* padding: 0 20px; */
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 390px;
  padding: 0 20px;
`;

export const ArticleCard = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  background: #fff;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid #ededef;
`;

export const CardImg = styled.div`
  width: 90px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const CardDesc = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
`;

export const CardCategory = styled.div`
  font-size: 12px;
  color: #3984f4;
  font-weight: 500;
  margin-bottom: 6px;
`;

export const CardTitle = styled.div`
  color: #000;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 9px;
`;

export const Author = styled.div`
  color: #8f9094;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 6px;
`;

export const Company = styled.div`
  color: #8f9094;
  font-size: 11px;
  font-weight: 500;
  em {
    font-size: 9px;
  }
`;

export const JobContainer = styled.div`
  width: 100%;
  max-width: 390px;
  overflow: hidden;
  height: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const JobWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 0 20px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const InsightContainer = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 0 20px;
  max-width: 390px;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const InsightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
  width: 1256px;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const InsightCard = styled.div`
  position: relative;
  width: 302px;
  height: 224px;
  cursor: pointer;

  img {
    border-radius: 8px;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background: lightgray 50% / cover no-repeat;
  }

  div {
    z-index: 1;
    position: absolute;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  span {
    position: absolute;
    z-index: 2;
    color: #fff388;
    font-size: 12px;
    line-height: 130%;
    bottom: 74px;
    left: 20px;
    font-weight: 600;
  }

  p {
    height: 48px;
    position: absolute;
    z-index: 2;
    color: white;
    font-size: 18px;
    line-height: 130%;
    bottom: 20px;
    left: 20px;
    font-weight: 700;
    width: 262px;
    word-break: keep-all;
  }
`;

export const TrendContainer = styled.div`
  width: 100%;
  max-width: 390px;
  margin: 0 auto;

  .swiper-slide {
    transition: transform 0.3s ease;
  }

  .swiper-slide-active {
    transform: scale(1);
  }

  .swiper-slide-next, .swiper-slide-prev {
    transform: scale(0.9);
  }

  .swiper-pagination {
    padding-left: 20px;
    width: 360px;
    top: 320px;
  }

  .swiper-pagination-bullet {
    width: 5px; /* 페이지네이션 점 크기 조절 */
    height: 5px;
    background-color: #868B94;
    border-radius: 50%;
    margin: 0 4px; /* 간격 조절 */
    transition: background-color 0.3s ease, transform 0.3s ease; /* 부드러운 전환 효과 */
  }

  .swiper-pagination-bullet-active {
    background-color: #3984f4; /* 활성화된 페이지네이션 점 색상 */
  }
`;


export const TrendCard = styled.div` 
  width: 340px;
  border-radius: 6px;;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px 0px;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-left: 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

export const TrendCardImg = styled.div`
  width: 100%;
  height: 178px;
  
  img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const TrendCardWrapper = styled.div`
  padding: 22px 20px;

`

export const TrendCardTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #212124;
  line-height: 140%;
`

export const TrendCardDesc = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #868B94;
  line-height: 140%;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  margin-bottom: 12px;
`