import * as S from "./Title.style";
import ARTICLE from "../../../assets/Icon_article.svg";
import JOB from "../../../assets/Icon_Job.svg";
import INSIGHT from "../../../assets/Icon_Interview.svg";
import CHAT from "../../../assets/CHAT.svg";
import DK from "../../../assets/ddakkm.svg";
import { useNavigate } from "react-router-dom";

const CARD = [
  {
    key: 1,
    title: "아티클",
    img: ARTICLE,
    url: "/article",
  },
  {
    key: 2,
    title: "채용공고",
    img: JOB,
    url: "/job",
  },
  {
    key: 3,
    title: "인사이트",
    img: INSIGHT,
    url: "/insight",
  },
  {
    key: 4,
    title: "커리어챗",
    img: CHAT,
    url: "/chat",
  },
];

function TitleSection() {
  const navigate = useNavigate();

  const navigatePage = (url: string) => {
    navigate(url, {
      replace: true,
      state: { scrollToTop: true },
    });
  };

  return (
    <S.Section>
      <S.TitleContainer>
        <S.TitleWrapper>
          <S.TitleAnimation>
            <S.Title>연구간호사가 되고 싶은 소현이도</S.Title>
            <S.Title>미국간호사가 되고 싶은 성호도</S.Title>
            <S.Title>AI 개발자가 되고 싶은 준혁이도</S.Title>
            <S.Title>보건관리자가 되고 싶은 단비도</S.Title>
          </S.TitleAnimation>
        </S.TitleWrapper>
      </S.TitleContainer>
      <S.Heading>
        간호사 커리어는 따끔
        <img src={DK} alt="따끔" />
      </S.Heading>
      <S.CardWrapper>
        {CARD.map((card) => (
          <S.Card
            key={card.key}
            onClick={() => {
              navigatePage(card.url);
            }}
          >
            <S.CardImg>
              <img src={card.img} alt="버튼 이미지" />
            </S.CardImg>
            <S.CardTitle>{card.title}</S.CardTitle>
          </S.Card>
        ))}
      </S.CardWrapper>
    </S.Section>
  );
}

export default TitleSection;
