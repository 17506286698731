import { useLocation, useNavigate } from "react-router-dom";
import { Job } from "../../utils/hooks/useJobList";
import * as S from "./JobCard.style";
import { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import JobContent from "../../views/JobPage/JobContent/JobContent";

interface JobCardProps {
  JobContent: Job;
}

function JobCard({ JobContent: job }: JobCardProps) {
  const navigate = useNavigate();

  const openModal = () => {
    navigate(`/job/${job.jobposting_id}`);
  };

  return (
    <>
      <S.Card onClick={openModal}>
        <S.Thumbnail>
          <img src={job.image} alt="회사 사진" />
        </S.Thumbnail>
        <S.TitleWrapper>
          <S.Title>{job.title}</S.Title>
          <S.Company>{job.company_name}</S.Company>
        </S.TitleWrapper>
      </S.Card>
    </>
  );
}

export default JobCard;
