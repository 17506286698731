import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/hooks/useAuth";
import * as S from "./ChatContent.style";
import X from "../../../assets/X.svg";
import { KakaoLoginButton } from "../../../views/LoginPage/components/LoginButton";
import { Chat } from "../../../utils/hooks/useChatList";
import { managementClient } from "../../../utils/managementClient";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

interface JobContentProps {
  Chat: Chat;
  onClose: () => void;
  onApplyUpdate: (updatedCount: number, likedStatus: boolean) => void;
  // initialApplyCount: number;
  // initialIsLiked: boolean;
}

export const ChatContent: React.FC<JobContentProps> = ({
  Chat,
  onClose,
  onApplyUpdate,
}) => {
  const [likeCount, setLikeCount] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useAuth();
  const { chatId } = useParams();
  const navigate = useNavigate();

  const fetchChatStatus = async () => {
    setIsLoading(true);
    try {
      const space = await managementClient.getSpace(
        process.env.REACT_APP_CONTENTFUL_SPACE!
      );
      const environment = await space.getEnvironment("master");
      const chatEntry = await environment.getEntry(Chat.entry_id!);
      const applyList = chatEntry.fields.apply?.["en-US"] || [];

      setLikeCount(applyList.length);

      if (user) {
        setIsLiked(applyList.includes(user.id.toString()));
      }
    } catch (error) {
      console.error("Error fetching chat status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    mixpanel.track("Contentview_Chat", {
      user: user ? "true" : "false",
      chatId: Chat.chatId,
      title: Chat.title,
    });
    fetchChatStatus();
  }, [user, chatId]);

  const updateArticleLikes = async (
    chatId: string,
    userId: string,
    isLiked: boolean
  ): Promise<string[]> => {
    try {
      const space = await managementClient.getSpace(
        process.env.REACT_APP_CONTENTFUL_SPACE!
      );
      const environment = await space.getEnvironment("master");
      const chatEntry = await environment.getEntry(chatId);

      let updatedLikes = chatEntry.fields.apply?.["en-US"] || [];
      if (!Array.isArray(updatedLikes)) {
        updatedLikes = [];
      }

      if (isLiked) {
        updatedLikes = updatedLikes.filter((id: string) => id !== userId);
      } else {
        updatedLikes.push(userId);
      }

      chatEntry.fields.apply = { "en-US": updatedLikes };
      const updatedEntry = await chatEntry.update();

      await updatedEntry.publish();
      mixpanel.track("Click_Apply_Button", {
        category: "chat",
        title: Chat?.title,
        id: Chat?.chatId,
      });

      return updatedLikes;
    } catch (error: any) {
      console.error("Error updating likes:", error);
      if (error.sys && error.sys.id === "VersionMismatch") {
        console.error("Version mismatch occurred. Retrying...");

        return updateArticleLikes(chatId, userId, isLiked);
      }
      throw error;
    }
  };

  const handleLikeToggle = async (event: React.MouseEvent<any>) => {
    event.preventDefault(); // 기본 동작 막기
    event.stopPropagation();

    if (!user) {
      navigate("/signin");
      return;
    }
    const userId = user.id.toString();
    const updatedIsLiked = !isLiked;
    const updatedLikeCount = isLiked ? likeCount - 1 : likeCount + 1;

    setIsLiked(updatedIsLiked);
    setLikeCount(updatedLikeCount);

    try {
      updatedIsLiked && toast.success("사전 신청이 완료됐어요!");
      await updateArticleLikes(Chat.entry_id!, userId, isLiked);
      onApplyUpdate(updatedLikeCount, updatedIsLiked);
    } catch (error) {
      console.error("Error updating likes:", error);
      setIsLiked(isLiked);
      setLikeCount(likeCount);
    }
  };

  return (
    <>
      <S.Section>
        <S.X onClick={onClose}>
          <img src={X} alt="닫기 버튼" />
        </S.X>
        {isLoading ? (
          <>
            <S.SkeletonImage />
            <S.ContentWrapper>
              <S.SkeletonTitle />
              <S.SkeletonContent />
              <S.SkeletonContent />
              <S.SkeletonContent />
              <S.SkeletonApply />
              <S.SkeletonButton />
            </S.ContentWrapper>
          </>
        ) : (
          <>
            <S.JobImage>
              <img src={Chat.image} alt="회사 이미지" />
            </S.JobImage>
            <S.ContentWrapper>
              <S.Status>{Chat.status}</S.Status>
              <S.Title>{Chat.title}</S.Title>
              <S.Content>{Chat.description}</S.Content>
              <S.CoffeChat>
                <em>☕ 커리어챗이란?</em>
                <br />
                20명이 모이면 현직자와 대화할 수 있는 줌 강연(커리어챗)이
                열려요. 사전 등록 신청하면 가장 먼저 알려드려요.
              </S.CoffeChat>
              <S.Apply>현재 {likeCount}명이 신청했어요.</S.Apply>
              <S.Send isLiked={isLiked} onClick={handleLikeToggle}>
                <span>{isLiked ? "신청 완료" : "사전 등록하기"}</span>
              </S.Send>
            </S.ContentWrapper>
          </>
        )}
        <Toaster />
      </S.Section>
      {/* ) : (
      <>
        <S.JoinModal>
          <S.JoinScript>
            지금 로그인하고
            <br />
            모든 커리어 정보를 받아 보세요!
          </S.JoinScript>
          <KakaoLoginButton />
        </S.JoinModal>
      </>
    )} */}
    </>
  );
};
