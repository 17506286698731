import styled, { keyframes } from "styled-components";

export const Section = styled.section`
  padding: 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoteImg = styled.div`
  width: 390px;
  height: 220px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 24px 20px;
  max-width: 390px;
  border-bottom: 10px solid #f5f6fa;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 24px 20px;
  max-width: 390px;
  p {
    color: #424857;
    font-size: 16px;
    font-weight: 500;
    line-height: 170%; /* 25.891px */
  }
`;

export const NoteTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%; /* 19.865px */
  margin-bottom: 10px;
`;

export const JobProfile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  span {
    color: #cbcfd7;
  }
`;

export const JobCompany = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`;

export const JobLocation = styled.div`
  color: #828795;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const JobInfo = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 12px;
`;

export const JobInfoDetail = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 8px;
`;

export const JobDetail = styled.div`
  color: #424857;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.891px */
  min-width: 42px;
`;

export const ApplyCTA = styled.div`
  position: fixed;
  bottom: 20px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: #3984f4;
    width: 90%;
    height: 60px;
    max-width: 390px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
  }
`;

export const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

// Skeleton Thumbnail
export const SkeletonThumbnail = styled.div`
  width: 100%;
  height: 220px; // 이미지 크기에 맞추어 설정
  border-radius: 8px;
  margin-bottom: 16px;
`;

// Skeleton Title Wrapper
export const SkeletonTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

// Skeleton Title
export const SkeletonTitle = styled.div`
  width: 70%; // 기본 너비
  height: 20px; // 기본 높이
  border-radius: 4px;
`;

// Skeleton Subtitle
export const SkeletonSubtitle = styled.div`
  width: 50%; // 기본 너비
  height: 16px; // 기본 높이
  border-radius: 4px;
`;

// Skeleton Chat Card
export const SkeletonChatCard = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  padding: 60px 16px 16px 16px;
  border-radius: 8px;
  width: 100%;
  height: 100vh;

  & > * {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 8%, #edeff1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    position: relative;
  }

  ${SkeletonThumbnail}, ${SkeletonTitle}, ${SkeletonSubtitle} {
    background-color: #eee;
  }
`;

export const Preview = styled.div`
  position: relative;
`;

export const Fader = styled.div`
  height: 250px;
  top: 0;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.95) 60%
  );
`;

export const JoinBanner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 400px;
  padding-top: 150px;
`;

export const JoinScript = styled.div`
  font-size: 20px;
  line-height: 140%;
  font-weight: 700;
  text-align: center;
  margin: 20px;
`;
