import * as S from "./Article.style";
import RightArrow from "../../../assets/Right_Arrow.svg";
import { useNavigate } from "react-router-dom";
import useInsightList from "../../../utils/hooks/useInsightList";

function Insight() {
  const navigate = useNavigate();
  const { insights } = useInsightList();

  return (
    <S.Section>
      <S.TitleWrapper>
        <div>
          <S.Title>
            나를 성장시키는 <em>커리어 인사이트</em>
          </S.Title>
          <S.Desc>간호사들의 새로운 도전 그리고 경험 이야기</S.Desc>
        </div>
        <S.RightArrow
          onClick={() => {
            navigate(`/insight`, {
              replace: true,
              state: { scrollToTop: true },
            });
          }}
        >
          <img src={RightArrow} alt="바로가기" />
        </S.RightArrow>
      </S.TitleWrapper>
      <S.InsightContainer>
        <S.InsightWrapper>
          {insights.slice(0, 4).map((insight) => (
            <S.InsightCard
              onClick={() => {
                navigate(`/insight/${insight.insight_id}`, {
                  state: { insight },
                });
              }}
              key={insight.insight_id}
            >
              <img src={insight?.image} alt="크리에이터 이미지" />
              <div />
              <span>{insight.category}</span>
              <p>{insight.title}</p>
            </S.InsightCard>
          ))}
        </S.InsightWrapper>
      </S.InsightContainer>
    </S.Section>
  );
}

export default Insight;
