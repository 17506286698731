import styled, { keyframes } from "styled-components";
import { onLarge } from "../../../styles/mediaQueries";

export const Section = styled.section`
  padding: 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  max-width: 390px;
  padding: 0 20px;
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 8px;
`;

export const Desc = styled.div`
  font-size: 14px;
  color: #8f9094;
  font-weight: 500;
  width: 100%;
  text-align: left;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 390px;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 28px;
  cursor: pointer;
`;

export const ListCardWrapper = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const ListCardImg = styled.div`
  width: 100px;
  height: 80px;

  img {
    border-radius: 12px;
    margin-top: 24px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Category = styled.div`
  color: #3984f4;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  color: #000;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
  margin-bottom: 6px;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
`;

export const Content = styled.div`
  color: #8f9094;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  margin-bottom: 20px;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const AuthorImg = styled.div`
  width: 36px;
  height: 36px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
`;

export const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AuthorName = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 500;
`;

export const AuthorCompany = styled.div`
  padding: 4px;
  border-radius: 4px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  font-weight: 600;
  inline-size: fit-content;
`;

export const BannerContainer = styled.div`
  height: 100px;
  padding: 22px 14px;
  background: linear-gradient(331deg, #00acd1 -6.98%, #e371ff 138.38%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  cursor: pointer;
`;

export const Banner = styled.div`
  width: 323px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BannerTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const BannerDesc = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 10px;
`;

export const ApplyCTA = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const BannerImg = styled.div`
  width: 69px;
  height: 50px;
  img {
    width: 100%;
  }
`;

const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`;

export const SkeletonCard = styled(ContentCard)`
  pointer-events: none;

  // shimmer 효과를 위한 클래스 추가
  .shimmer {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 8%, #edeff1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    position: relative;
  }

  ${Category}, ${Title}, ${Content}, ${AuthorImg}, ${AuthorName}, ${AuthorCompany} {
    background-color: #eee;
  }

  ${Category} {
    width: 30%;
    height: 14px;
    margin-bottom: 10px;
    border-radius: 4px;
  }

  ${Title} {
    width: 80%;
    height: 24px;
    margin-bottom: 12px;
    border-radius: 4px;
  }

  ${Content} {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 4px;
  }

  ${AuthorImg} {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  ${AuthorName}, ${AuthorCompany} {
    width: 80px;
    height: 12px;
    margin-bottom: 4px;
    border-radius: 4px;
  }
`;

export const InterviewCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  /* width: 322px; */
  cursor: pointer;
`;

export const InterviewImg = styled.div`
  width: 100%;
  height: 224px;
  margin-bottom: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const InterviewCategory = styled.div`
  margin-bottom: 8px;
  span {
    font-size: 14px;
    line-height: 20px;
    color: #3984f4;
    font-weight: 600;
    /* padding: 2px 6px; */
    margin-bottom: 8px;
    /* background-color: #f5f6fa; */
    border-radius: 4px;
  }
`;

export const InterviewTitle = styled.div`
  color: #424857;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 6px;
  word-break: keep-all;
`;

export const InterviewPreview = styled.div`
  color: #424857;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  /* word-break: keep-all; */
`;
