import { useNavigate } from "react-router-dom";
import useJobPostingList from "../../../utils/hooks/useJobList";
import * as S from "./Article.style";
import RightArrow from "../../../assets/Right_Arrow.svg";
import ChatCard from "../../../components/ChatCard/ChatCard";
import useChatList from "../../../utils/hooks/useChatList";

function Chat() {
  const { chats } = useChatList();
  const navigate = useNavigate();

  return (
    <S.Section>
      <S.TitleWrapper>
        <div>
          <S.Title>
            현직자를 만날 수 있는 <em>커리어챗</em>
          </S.Title>
          <S.Desc>궁금한 커리어 정보를 현직자에게 직접 물어보세요!</S.Desc>
        </div>
        <S.RightArrow
          onClick={() => {
            navigate(`/chat`, { replace: true, state: { scrollToTop: true } });
          }}
        >
          <img src={RightArrow} alt="바로가기" />
        </S.RightArrow>
      </S.TitleWrapper>
      <S.JobContainer>
        <S.JobWrapper>
          {chats.slice(0, 5).map((chat) => (
            <ChatCard ChatContent={chat} key={chat.chatId} />
          ))}
        </S.JobWrapper>
      </S.JobContainer>
    </S.Section>
  );
}

export default Chat;
