import styled, { keyframes } from "styled-components";
import { onLarge } from "../../../styles/mediaQueries";
import { Card } from "../../../components/JobCard/JobCard.style";

export const Section = styled.section`
  padding: 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;
  max-width: 390px;
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 8px;
`;

export const Desc = styled.div`
  font-size: 14px;
  color: #8f9094;
  font-weight: 500;
  width: 100%;
  text-align: left;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 390px;
`;

export const JobContaier = styled.div`
  display: flex;
  width: 100%;
  max-width: 390px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const JobWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
`;

export const BannerContainer = styled.div`
  height: 100px;
  padding: 22px 14px;
  background: linear-gradient(331deg, #ff5f6d -6.98%, #ffc371 138.38%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
`;

export const Banner = styled.div`
  width: 323px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BannerTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const BannerDesc = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
`;

export const BannerImg = styled.div`
  width: 52px;
  height: 52px;
  img {
    width: 100%;
  }
`;

const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`;

export const Thumbnail = styled.div`
  width: 169px;
  height: 116px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0px 0px;
  }
`;

export const Company = styled.div`
  color: #8f9094;
  font-size: 12px;
  font-weight: 600;
`;

export const Title = styled.div`
  width: 142px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  line-height: 1.2;
`;

export const SkeletonJobCard = styled(Card)`
  pointer-events: none;

  & > * {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 8%, #edeff1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    position: relative;
  }

  ${Thumbnail}, ${Title}, ${Company} {
    background-color: #eee;
  }
`;
