import { useQuery } from "react-query";
import { createClient } from "contentful";
import { Entry, Asset } from "contentful";
import { client } from "../../utils/client";
import { useEffect, useState } from "react";

// Keyword 인터페이스 정의
export interface Keyword {
  id: number;
  keyword: string;
}

export interface Company {
  name: string;
  id: number;
  category: string;
  class: string;
  image: Asset | null;
}

export interface JobPosting {
  jobposting_id: number;
  category: string;
  title: string;
  image: string;
  location: string;
  experience: string;
  deadline: string;
  deadline_date: boolean;
  content: any;
  keyword: string[];
  link: string;
  attribute: string;
  company_name: string;
  company: Company;
  keywords: Keyword[];
}

const useJobPostings = (jobpostingId: string) => {
  const [jobPosting, setJobPosting] = useState<JobPosting | null>(null);

  const { isFetching, isError, data, error, refetch } = useQuery(
    ["jobPostings", jobpostingId],
    async () => {
      const response = await client.getEntries({
        content_type: "jobposting",
        include: 1,
        "fields.jobpostingId": jobpostingId,
      });

      const item = response.items[0];
      if (item && item.fields) {
        const fields = item.fields as any;

        const jobPostingData: JobPosting = {
          jobposting_id:
            typeof fields.jobpostingId === "number" ? fields.jobpostingId : 0,
          category: typeof fields.category === "string" ? fields.category : "",
          title: typeof fields.title === "string" ? fields.title : "",
          image: fields?.image?.fields?.file?.url,
          location: typeof fields.location === "string" ? fields.location : "",
          experience:
            typeof fields.experience === "string" ? fields.experience : "",
          deadline: typeof fields.deadline === "string" ? fields.deadline : "",
          deadline_date:
            typeof fields.deadline_date === "boolean"
              ? fields.deadline_date
              : false,
          content: fields.content || {},
          keyword: Array.isArray(fields.keyword)
            ? fields.keyword.filter(
                (k: unknown): k is string => typeof k === "string"
              )
            : [],
          link: typeof fields.link === "string" ? fields.link : "",
          attribute:
            typeof fields.attribute === "string" ? fields.attribute : "",
          company_name:
            typeof fields.company_name === "string" ? fields.company_name : "",
          company:
            fields.company && typeof fields.company === "object"
              ? {
                  name: fields.company.fields?.name || "",
                  id: fields.company.fields?.id || 0,
                  category: fields.company.fields?.category || "",
                  class: fields.company.fields?.class || "",
                  image: fields.company.fields?.image.fields.file.url || null,
                }
              : {
                  name: "",
                  id: 0,
                  category: "",
                  class: "",
                  image: null,
                },
          keywords: Array.isArray(fields.keywords)
            ? fields.keywords
                .map((keyword: any) =>
                  keyword && typeof keyword === "object" && keyword.fields
                    ? {
                        id: keyword.fields.id || 0,
                        keyword: keyword.fields.keyword || "",
                      }
                    : null
                )
                .filter((k: Keyword | null): k is Keyword => k !== null)
            : [],
        };

        setJobPosting(jobPostingData);
      } else {
        setJobPosting(null);
      }
    },
    {
      enabled: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    jobPosting,
    loading: isFetching,
    error: isError ? error : null,
    refetch,
  };
};

export default useJobPostings;
