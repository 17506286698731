import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import mixpanel from "mixpanel-browser";

const queryClient = new QueryClient();

mixpanel.init(process.env.REACT_APP_MIXPANEL!, {
  ignore_dnt: true,
  secure_cookie: true,
  persistence: "localStorage",
  track_pageview: false,
  debug: false,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
