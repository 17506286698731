import { useEffect } from "react";
import { client } from "../client";
import { useQuery } from "react-query";

export interface Author {
  author_id: string;
  author_name: string;
  author_image?: string | "";
  author_profile: string;
  registered_date: string;
  company: string;
  experience: number;
}

export interface Insight {
  insight_id: string;
  entry_id: string;
  category: string;
  title: string;
  image?: string | "";
  preview: string;
  content: any;
  author: Author;
  attribute: "기본" | "인기";
  likes: string[];
  createdAt: string;
}

export interface Article {
  article_id: string;
  entry_id: string;
  category: string;
  title: string;
  image?: string | "";
  preview: string;
  content: any;
  author: Author;
  likes: string[];
  createdAt: string;
}

const useAuthorContent = (authorId: string) => {
  const fetchInsights = async () => {
    const response = await client.getEntries<any>({
      content_type: "insight",
      include: 1,
      "fields.author.sys.contentType.sys.id": "author",
      "fields.author.fields.authorId": authorId,
    });

    return response.items.map((item: any) => {
      const { fields, sys } = item;
      return {
        insight_id: fields.insightId,
        entry_id: sys.id,
        title: fields.title,
        image: fields.image?.fields?.file?.url || "",
        preview: fields.content.content[0].content[0].value,
        content: fields.content,
        attribute: fields.attribute,
        category: fields.category,
        createdAt: sys.createdAt,
        likes: fields?.likes || [],
        author: {
          author_id: fields.author.fields.authorId,
          author_name: fields.author.fields.authorName,
          author_image:
            fields.author.fields?.author_image?.fields?.file?.url || "",
          author_profile: fields.author.fields.authorProfile,
          registered_date: fields.author.fields.registered_date,
          company: fields.author.fields.company,
          experience: fields.author.fields.experience,
        },
      } as Insight;
    });
  };

  const fetchArticles = async () => {
    const response = await client.getEntries<any>({
      content_type: "article",
      include: 1,
      "fields.author.sys.contentType.sys.id": "author",
      "fields.author.fields.authorId": authorId,
    });

    return response.items.map((item: any) => {
      const { fields, sys } = item;
      return {
        article_id: fields.articleId,
        entry_id: sys.id,
        title: fields.title,
        image: fields.image?.fields?.file?.url || "",
        preview: fields.content.content[0].content[0].value,
        content: fields.content,
        category: fields.category,
        createdAt: sys.createdAt,
        likes: fields?.likes || [],
        author: {
          author_id: fields.author.fields.authorId,
          author_name: fields.author.fields.authorName,
          author_image:
            fields.author.fields?.author_image?.fields?.file?.url || "",
          author_profile: fields.author.fields.authorProfile,
          registered_date: fields.author.fields.registered_date,
          company: fields.author.fields.company,
          experience: fields.author.fields.experience,
        },
      } as Article;
    });
  };

  const {
    isFetching: isFetchingInsights,
    data: insightsData,
    error: insightsError,
    refetch: refetchInsights,
  } = useQuery(["insights", authorId], fetchInsights, {
    enabled: !!authorId,
    staleTime: 60 * 60 * 1000,
  });

  const {
    isFetching: isFetchingArticles,
    data: articlesData,
    error: articlesError,
    refetch: refetchArticles,
  } = useQuery(["articles", authorId], fetchArticles, {
    enabled: !!authorId,
    staleTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    if (authorId) {
      refetchInsights();
      refetchArticles();
    }
  }, [authorId, refetchInsights, refetchArticles]);

  return {
    insights: insightsData || [],
    articles: articlesData || [],
    contentLoading: isFetchingInsights || isFetchingArticles,
    error: insightsError || articlesError,
    refetch: () => {
      refetchInsights();
      refetchArticles();
    },
  };
};

export default useAuthorContent;
