import styled, { keyframes } from "styled-components";
import React, { useState } from "react";

export const Card = styled.div`
  border-radius: 16px;
  background: #fff;
  border: 1px solid #ededef;
  /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08); */
  width: 100%;
  min-width: 240px;
  
  cursor: pointer;
`;

export const Thumbnail = styled.div`
  width: 100%;
  height: 155px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    /* border-radius: 6px; */
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 6px; */
  padding: 12px 12px;
`;

export const Title = styled.div`
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  line-height: 1.4;
`;

export const Status = styled.div`
  color: #3984f4;
  font-size: 12px;
  font-weight: 700;
  margin-top: 12px;
`;

export const Apply = styled.div`
  color: #8f9094;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
`;
