import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { User, useAuth } from "../../utils/hooks/useAuth";
import styled from "styled-components";
import { SyncLoader } from "react-spinners";
import mixpanel from "mixpanel-browser";

const Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

interface Res {
  access_token: string;
  refresh_token: string;
  user: User;
}

function KakaoRedirectHandler() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const returnUrl = params.get("state") || "/";

    if (code) {
      axios
        .post(
          // `http://localhost:5002/auth/kakao/callback`,
          `${process.env.REACT_APP_API_URL}/auth/kakao/callback`,
          { code },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          const { access_token, refresh_token, user }: Res = response.data;
          login(user, { access_token, refresh_token });
          mixpanel.alias(`${user?.id}`);
          mixpanel.identify(`${user?.id}`);
          mixpanel.people.set({
            $name: user.name,
            $email: user.email,
            $created: user.created_at,
            $last_login: new Date(),
          });

          navigate(returnUrl);
        })
        .catch((error) => {
          console.error("Login error:", error);
          navigate("/signin");
        });
    } else {
      navigate("/signin");
    }
  }, [navigate, login]);

  return (
    <Section>
      <SyncLoader color="#3984F4" />
    </Section>
  );
}

export default KakaoRedirectHandler;
