import { useEffect } from "react";
import { KakaoLoginButton } from "./components/LoginButton";
import styled from "styled-components";
import mixpanel from "mixpanel-browser";

const LoginContainer = styled.section`
  padding: 140px 40px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  justify-content: center;
  align-items: center;
`;

const LoginPageWrapper = styled.div`
  width: 100%;
  max-width: 390px;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin: 50px 0px 100px;
  line-height: 150%;
`;

function LoginPage() {
  useEffect(() => {
    mixpanel.track("Pageview_Login");
  }, []);

  return (
    <LoginContainer>
      <LoginPageWrapper>
        <Title>
          따끔으로 <br />
          커리어를 앞서가세요!
        </Title>
        <KakaoLoginButton />
      </LoginPageWrapper>
    </LoginContainer>
  );
}

export default LoginPage;
