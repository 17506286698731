import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../utils/hooks/useAuth";
import useJobPostings from "../../../utils/hooks/useJobContent";
import * as S from "./NewJobContent.style";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { KakaoLoginButton } from "../../../views/LoginPage/components/LoginButton";

export default function NewJobContent() {
  const { jobId } = useParams<{ jobId: string }>();
  const { jobPosting, loading } = useJobPostings(jobId!);
  const { user } = useAuth();
  const isPastDeadline = (deadline: string) => {
    const today = new Date();
    const [year, month, day] = deadline.split(".").map(Number);
    const deadlineDate = new Date(year, month - 1, day);
    return deadlineDate < today; // true면 마감 false면 가능
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanel.track("Pageview_JobDetail", {
      user: user ? "true" : "false",
      title: jobPosting?.title,
      category: jobPosting?.category,
    });
  }, []);

  return (
    <>
      {loading ? (
        <S.SkeletonChatCard>
          <S.SkeletonThumbnail
            style={{ height: "220px", marginBottom: "16px" }}
          />
          <S.SkeletonTitleWrapper>
            <S.SkeletonTitle
              style={{ width: "80%", height: "24px", marginBottom: "10px" }}
            />
            <S.SkeletonSubtitle style={{ width: "60%", height: "18px" }} />
          </S.SkeletonTitleWrapper>
          <S.SkeletonTitleWrapper>
            <S.SkeletonTitle
              style={{ width: "100%", height: "18px", marginBottom: "8px" }}
            />
            <S.SkeletonTitle
              style={{ width: "100%", height: "18px", marginBottom: "8px" }}
            />
            <S.SkeletonTitle
              style={{ width: "100%", height: "18px", marginBottom: "8px" }}
            />
          </S.SkeletonTitleWrapper>
        </S.SkeletonChatCard>
      ) : (
        jobPosting && (
          <>
            <S.Section>
              <S.NoteImg>
                <img src={jobPosting.image} alt="인터뷰 이미지" />
              </S.NoteImg>
              <S.Container>
                <S.NoteTitle>{jobPosting.title}</S.NoteTitle>
                <S.JobProfile>
                  <S.JobCompany>{jobPosting.company_name}</S.JobCompany>
                  <span>|</span>
                  <S.JobLocation>{jobPosting.location}</S.JobLocation>
                </S.JobProfile>
              </S.Container>
              {user ? (
                <>
                  <S.Container>
                    <S.JobInfo>기본 정보</S.JobInfo>
                    <S.JobInfoDetail>
                      <S.JobDetail>마감일</S.JobDetail>
                      <S.JobDetail>{jobPosting.deadline}</S.JobDetail>
                    </S.JobInfoDetail>
                    <S.JobInfoDetail>
                      <S.JobDetail>경력</S.JobDetail>
                      <S.JobDetail>{jobPosting.experience}</S.JobDetail>
                    </S.JobInfoDetail>
                    <S.JobInfoDetail>
                      <S.JobDetail>근무지</S.JobDetail>
                      <S.JobDetail>{jobPosting.location}</S.JobDetail>
                    </S.JobInfoDetail>
                  </S.Container>
                  <S.ContentWrapper>
                    <S.JobInfo>상세 내용</S.JobInfo>
                    <p>
                      <>{documentToReactComponents(jobPosting.content)}</>
                    </p>
                  </S.ContentWrapper>

                  <S.ApplyCTA>
                    {isPastDeadline(jobPosting.deadline) ? (
                      <button>
                        <span>마감된 공고입니다</span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          window.open(jobPosting.link);
                          mixpanel.track("Click_Outlink_Job", {
                            user: user ? "true" : "false",
                            contentId: jobPosting.jobposting_id,
                            contentTitle: jobPosting?.title,
                          });
                        }}
                      >
                        <span>지원하기</span>
                      </button>
                    )}
                  </S.ApplyCTA>
                </>
              ) : (
                <>
                  <S.Preview>
                    {/* <S.Fader /> */}
                    <S.JoinBanner>
                      <S.JoinScript>
                        지금 로그인하고
                        <br />
                        모든 채용 정보를 확인하세요!
                      </S.JoinScript>
                      <KakaoLoginButton />
                    </S.JoinBanner>
                  </S.Preview>
                </>
              )}
            </S.Section>
          </>
        )
      )}
    </>
  );
}
