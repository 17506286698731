import { useEffect, useState } from "react";
import { client } from "../client";
import { useQuery } from "react-query";
export interface AuthorFields {
  authorId: string;
  authorName: string;
  author_image?: {
    fields: {
      file: {
        url: string;
      };
    };
  };
  authorProfile: string;
  registered_date: string;
  company: string;
  experience: number;
}

export interface Author {
  fields: AuthorFields;
}
export interface Note {
  note_id: string;
  entry_id: string;
  title: string;
  image?: string | "";
  // preview: any;
  content: any;
  createdAt: string;
  author: any;
}

export interface ImageFields {
  file: {
    url: string;
  };
}

export interface AuthorFields {
  authorId: string;
  authorName: string;
  author_image?: {
    fields: ImageFields;
  };
  authorProfile: string;
  registered_date: string;
  company: string;
  experience: number;
}

export interface ContentFields {
  data: any;
  content: any[];
  nodeType: string;
}

export interface Fields {
  author: {
    fields: AuthorFields;
  };
  content: ContentFields;
  image?: {
    fields: ImageFields;
  };
  noteId: string;
  title: string;
}

const useNoteContent = (note_id: string) => {
  const [note, setNote] = useState<Note | null>(null);
  // const [note, setNote] = useState<Note>(null);

  const { isFetching, isError, data, error, refetch } = useQuery(
    ["test"],
    async () => {
      const response = await client.getEntries<any>({
        content_type: "test",
        include: 1,
        "fields.noteId": note_id,
      });

      const item = response.items[0];
      if (item) {
        const { fields, sys } = item;

        console.log(item);

        let imageUrl: string | undefined;

        // fields.image가 올바른 타입인지 확인합니다.
        if (
          fields.image &&
          typeof fields.image === "object" &&
          "fields" in fields.image
        ) {
          const imageFields = (fields.image as any).fields;
          if (
            imageFields.file &&
            typeof imageFields.file === "object" &&
            "url" in imageFields.file
          ) {
            imageUrl = imageFields.file.url;
          }
        }

        const noteData: Note = {
          note_id: String(fields.noteId),
          entry_id: sys.id,
          title: String(fields.title),
          image: imageUrl,
          content: fields.content,
          createdAt: sys.createdAt,
          author: fields.author,
          // preview: String(fields.content?.content[0]?.content[0]?.value)
        };
        setNote(noteData);
      } else {
        setNote(null);
      }
    },
    {
      enabled: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    note,
    loading: isFetching,
    error: isError ? error : null,
    refetch,
  };
};

export default useNoteContent;
