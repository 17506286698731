import styled, { keyframes } from "styled-components";

export const Section = styled.section`
  padding: 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  margin-bottom: 70px;
`;

export const InterviewAuthorImg = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  img {
    border: 0.961px solid #dfdfdf;
    border-radius: 48px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const InterviewAuthorName = styled.div`
  color: #424857;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%; /* 27px */
  margin-bottom: 6px;
`;

export const InterviewAuthorCareer = styled.div`
  color: #424857;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-bottom: 10px;
`;

export const InterviewAuthorProfile = styled.div`
  color: #9195a0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  padding: 0 12px;
  word-break: keep-all;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  max-width: 390px;
  padding: 0 20px;
`;

export const Heading = styled.h1`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 8px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 390px;
`;

export const InterviewCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  /* width: 322px; */
  cursor: pointer;
`;

export const InterviewImg = styled.div`
  width: 100%;
  height: 224px;
  margin-bottom: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const InterviewCategory = styled.div`
  margin-bottom: 8px;
  span {
    font-size: 14px;
    line-height: 20px;
    color: #3984f4;
    font-weight: 600;
    /* padding: 2px 6px; */
    margin-bottom: 8px;
    /* background-color: #f5f6fa; */
    border-radius: 4px;
  }
`;

export const InterviewTitle = styled.div`
  color: #424857;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 6px;
  word-break: keep-all;
`;

export const InterviewPreview = styled.div`
  color: #424857;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  /* word-break: keep-all; */
`;

const placeHolderShimmer = keyframes`
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
`;

// Skeleton styles
export const SkeletonWrapper = styled.div`
  width: 100%;
`;

export const SkeletonAuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  align-items: center;
  margin-bottom: 70px;
  & > * {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 8%, #edeff1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    position: relative;
  }
`;

export const SkeletonAuthorImg = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 48px;
  margin-bottom: 10px;
  background-color: #eee;
`;

export const SkeletonAuthorName = styled.div`
  width: 100px;
  height: 20px;
  margin-bottom: 6px;
  background-color: #eee;
`;

export const SkeletonAuthorCareer = styled.div`
  width: 150px;
  height: 18px;
  margin-bottom: 10px;
  background-color: #eee;
`;

export const SkeletonAuthorProfile = styled.div`
  width: 200px;
  height: 14px;
  margin-bottom: 10px;
  background-color: #eee;
`;

export const SkeletonHeadingContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  max-width: 390px;
  padding: 0 20px;
`;

export const SkeletonHeading = styled.div`
  width: 200px;
  height: 24px;
  background-color: #eee;
  margin-bottom: 8px;
`;

export const SkeletonContentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 390px;
`;

export const SkeletonInterviewCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  & > * {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 8%, #edeff1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    position: relative;
  }
`;

export const SkeletonInterviewImg = styled.div`
  width: 100%;
  height: 224px;
  margin-bottom: 12px;
  background-color: #eee;
  border-radius: 8px;
`;

export const SkeletonInterviewCategory = styled.div`
  width: 50px;
  height: 20px;
  margin-bottom: 8px;
  background-color: #eee;
`;

export const SkeletonInterviewTitle = styled.div`
  width: 150px;
  height: 20px;
  margin-bottom: 6px;
  background-color: #eee;
`;

export const SkeletonInterviewPreview = styled.div`
  width: 200px;
  height: 30px;
  background-color: #eee;
`;
