import { useEffect, useState } from "react";
import { client } from "../client";
import { useQuery } from "react-query";

export interface Author {
  author_id: string;
  author_name: string;
  author_image?: string | "";
  author_profile: string;
  company: string;
  category: string;
  experience: string;
}

const useAuthor = (authorId: string) => {
  const [author, setAuthor] = useState<Author | null>(null);

  const { isFetching, isError, data, error, refetch } = useQuery(
    ["author"],
    async () => {
      const response = await client.getEntries<any>({
        content_type: "author",
        include: 1,
        "fields.authorId": authorId,
      });

      const item = response.items[0];
      if (item) {
        const { fields } = item;

        let imageUrl: string | undefined;

        // fields.image가 올바른 타입인지 확인합니다.
        if (
          fields.author_image &&
          typeof fields.author_image === "object" &&
          "fields" in fields.author_image
        ) {
          const imageFields = (fields.author_image as any).fields;
          if (
            imageFields.file &&
            typeof imageFields.file === "object" &&
            "url" in imageFields.file
          ) {
            imageUrl = imageFields.file.url;
          }
        }

        const noteData: Author = {
          author_id: String(fields.authorId),
          author_name: String(fields.authorName),
          author_profile: String(fields.authorProfile),
          company: String(fields.company),
          category: String(fields.category),
          experience: String(fields.experience),
          author_image: imageUrl,
        };
        setAuthor(noteData);
      } else {
        setAuthor(null);
      }
    },
    {
      enabled: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    author,
    loading: isFetching,
    error: isError ? error : null,
    refetch,
  };
};

export default useAuthor;
