import { useEffect } from "react";
import Article from "./components/Article";
import BannerSection from "./components/Banner";
import Insight from "./components/Insight";
import Job from "./components/Job";
import TitleSection from "./components/Title";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import SEO from "../../components/Helmet/Helmet";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../utils/hooks/useAuth";
import TrendSection from "./components/Trend";
import Chat from "./components/Chat";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

function MainPage() {
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
    mixpanel.track("Pageview_Main", {
      user: user ? "true" : "false",
    });
  }, [location]);

  return (
    <>
      <SEO />
      <Page>
        <TitleSection />
        <BannerSection />
        <Insight />
        <Job />
        <TrendSection />
        <Chat />
        <Article />
      </Page>
    </>
  );
}

export default MainPage;
