import styled, { keyframes } from "styled-components";
import { onLarge } from "../../../styles/mediaQueries";
import { Card } from "../../../components/JobCard/JobCard.style";

export const Section = styled.section`
  padding: 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  padding: 30px 20px;
  /* margin-bottom: 30px; */
  max-width: 390px;
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 8px;
`;

export const Desc = styled.div`
  font-size: 14px;
  color: #8f9094;
  font-weight: 500;
  width: 100%;
  text-align: left;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 390px;
`;

export const ChatContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 390px;
  flex-direction: column;
  gap: 16px;
`;

export const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const SkeletonThumbnail = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 8px;
`;

export const SkeletonTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const SkeletonTitle = styled.div`
  width: 70%;
  height: 20px;
  border-radius: 4px;
`;

export const SkeletonSubtitle = styled.div`
  width: 50%;
  height: 16px;
  border-radius: 4px;
`;

export const SkeletonChatCard = styled(Card)`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  padding: 16px;
  border-radius: 8px;
  width: 100%;

  & > * {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #f6f7f8 8%, #edeff1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    position: relative;
  }

  ${SkeletonThumbnail}, ${SkeletonTitle}, ${SkeletonSubtitle} {
    background-color: #eee;
  }
`;
