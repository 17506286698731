import * as S from "./ChatList.style";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../../components/Helmet/Helmet";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../../utils/hooks/useAuth";
import useChatList from "../../../utils/hooks/useChatList";
import ChatCard from "../../../components/ChatCard/ChatCard";

function ChatList() {
  const { user } = useAuth();
  const location = useLocation();
  const { chats, loading } = useChatList();

  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanel.track("Pageview_ChatList", {
      user: user ? "true" : "false",
    });
  }, []);

  return (
    <>
      <SEO />
      <S.Section>
        {/* <S.BannerContainer
          onClick={() => {
            window.open(`https://tally.so/r/mV8VRM`);
            mixpanel.track("Click_Banner_Job_JobList");
          }}
        >
          <S.Banner>
            <S.TitleWrapper>
              <S.BannerTitle>만나고 싶은 현직자가 있나요?</S.BannerTitle>
              <S.BannerDesc></S.BannerDesc>
            </S.TitleWrapper>
            <S.BannerImg>
              <img src={Hospital} alt="채용공고 무료 업로드" />
            </S.BannerImg>
          </S.Banner>
        </S.BannerContainer> */}
        <S.HeadingContainer>
          <S.Heading>커리어챗</S.Heading>
          <S.Desc>궁금한 커리어 정보를 현직자에게 직접 물어보세요!</S.Desc>
        </S.HeadingContainer>
        <S.ContentWrapper>
          <S.ChatContainer>
            {loading
              ? Array.from({ length: 5 }).map((_, idx) => (
                  <S.SkeletonChatCard key={idx}>
                    <S.SkeletonThumbnail className="shimmer" />
                    <S.SkeletonTitleWrapper>
                      <S.SkeletonTitle className="shimmer" />
                      <S.SkeletonSubtitle className="shimmer" />
                    </S.SkeletonTitleWrapper>
                  </S.SkeletonChatCard>
                ))
              : chats.map((chat, idx) => (
                  <ChatCard ChatContent={chat} key={chat.chatId} />
                ))}
          </S.ChatContainer>
        </S.ContentWrapper>
      </S.Section>
    </>
  );
}

export default ChatList;
