// src/atoms/authAtoms.ts
import { atom } from "jotai";

interface Tokens {
  access_token: string | null;
  refresh_token: string | null;
}

interface User {
  id: number;
  nickname: string;
  profile_image: string;
  email: string;
  name: string;
  gender: string;
  birthday?: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

const storedAccessToken = localStorage.getItem("access_token");
const storedRefreshToken = localStorage.getItem("refresh_token");

export const userAtom = atom<User | null>(null);
export const tokensAtom = atom<Tokens>({
  access_token: null,
  refresh_token: null,
});
