import styled from "styled-components";
import { onNotLarge } from "../../styles/mediaQueries";

export const HeaderSection = styled.header`
  background-color: #fff;
  border-bottom: 1px #000;
  width: 100%;
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;

export const HeaderContainer = styled.div`
  padding: 12px 20px;
  width: 100%;
  max-width: 390px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  align-self: center;
  width: 65px;
  height: 32px;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
    ${onNotLarge} {
    }
  }
`;

export const Login = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  color: #666e7b;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
  /* ${onNotLarge} {
    padding-left: 20px;
    padding-right: 20px;
  } */
`;

export const BackButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const ProfileImg = styled.div`
  width: 40px;
  height: 40px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  ${onNotLarge} {
    width: 32px;
    height: 32px;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
  align-items: center;
`;

export const HamburgerMenu = styled.div`
  width: 18px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
  }
`;

export const DropdownMenu = styled.nav<{ open: boolean }>`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid #001b371a;
  z-index: 1000;

  ${onNotLarge} {
    display: ${({ open }) => (open ? "flex" : "none")};
  }
`;

export const ItemWrappper = styled.ul`
  width: 100%;
  max-width: 390px;
  /* padding: 0 20px; */
  display: flex;
  flex-direction: column;
`;

export const DropdownItem = styled.li`
  padding: 15px 20px;
  color: #333;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  list-style-type: none;

  &:hover {
    background-color: #f8f9fa;
  }
`;
