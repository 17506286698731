import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../utils/hooks/useAuth";
import useNoteContent from "../../utils/hooks/useNoteContent";
import * as S from "../AritclePage/ArticleContent/ArticleContent.style";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { KakaoLoginButton } from "../../views/LoginPage/components/LoginButton";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

function NotePage() {
  const { noteId } = useParams<{ noteId: string }>();
  const { note } = useNoteContent(noteId!);
  const { user } = useAuth();
  const navigate = useNavigate();

  console.log(note);

  useEffect(() => {
    mixpanel.track("Contentview_Note", {
      noteId,
      noteTitle: note?.title,
      user: user ? "true" : "false",
    });
  }, []);

  return (
    <>
      {note && (
        <>
          <S.Section>
            <S.NoteImg>
              <img src={note.image} alt="인터뷰 이미지" />
            </S.NoteImg>
            <S.Container>
              {/* <S.InterviewCategory>{insight.category}</S.InterviewCategory> */}
              <S.NoteTitle>{note.title}</S.NoteTitle>

              <S.NoteWrapper>
                {!user && (
                  <>
                    <S.NoteFader />
                    <S.NoteJoinBanner>
                      <S.JoinScript>
                        지금 로그인하고
                        <br />
                        간호사 커리어를 앞서가세요!
                      </S.JoinScript>
                      <KakaoLoginButton />
                    </S.NoteJoinBanner>
                  </>
                )}
                {documentToReactComponents(note.content)}
                <S.AuthorWrapper>
                  <S.InterviewAuthorImg>
                    <img
                      src={note.author.fields?.author_image.fields?.file.url}
                      alt="크리에이터 이미지"
                    />
                  </S.InterviewAuthorImg>
                  <S.InterviewAuthorName>
                    {note.author.fields?.authorName}
                  </S.InterviewAuthorName>
                  <S.InterviewAuthorCareer>
                    {note.author.fields?.company} ·{" "}
                    {note.author.fields?.experience}
                  </S.InterviewAuthorCareer>
                  <S.InterviewAuthorProfile>
                    {note.author.fields?.authorProfile}
                  </S.InterviewAuthorProfile>
                  <S.AuthorProfileBtn
                    onClick={() => {
                      navigate(`/author/${note.author.fields?.authorId}`);
                    }}
                  >
                    <span>선생님 글 더보기</span>
                  </S.AuthorProfileBtn>
                </S.AuthorWrapper>
              </S.NoteWrapper>
            </S.Container>
          </S.Section>
        </>
      )}
    </>
  );
}

export default NotePage;
