import logo from "../../assets/ddkk_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks/useAuth";
import * as S from "./Header.style";
import { useState } from "react";
import Hamburger from "../../assets/Hamburger.svg";
import X from "../../assets/Vector 550.svg";
import LeftArrow from "../../assets/LeftArrow.svg";

function Header() {
  const navigate = useNavigate();
  const { user, checkUser, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const showBackButton =
    location.pathname.startsWith("/article/") ||
    location.pathname.startsWith("/insight/") ||
    location.pathname.startsWith("/author/") ||
    location.pathname.startsWith("/job/");

  const handleLoginClick = () => {
    if (
      window.location.href == "http://localhost:3000/" ||
      process.env.REACT_APP_NODE_ENV == "development"
    ) {
      checkUser();
    } else {
      navigate("/signin");
    }
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleBackClick = () => {
    if (document.referrer.includes("ddakkm.me")) {
      navigate(-1);
    } else {
      if (location.pathname.startsWith("/article")) {
        navigate("/article");
      } else if (location.pathname.startsWith("/insight")) {
        navigate("/insight");
      } else if (location.pathname.startsWith("/job")) {
        navigate("/job");
      } else navigate(-1);
    }
  };

  return (
    <S.HeaderSection>
      <S.HeaderContainer>
        {showBackButton ? (
          <S.BackButton onClick={() => handleBackClick()}>
            <img src={LeftArrow} alt="뒤로가기" />
          </S.BackButton>
        ) : (
          <S.Logo>
            <img
              src={logo}
              alt="logo"
              onClick={() => {
                navigate("/", {
                  replace: true,
                  state: { scrollToTop: true },
                });
                setMenuOpen(false);
              }}
            />
          </S.Logo>
        )}

        <S.MenuWrapper>
          {user ? (
            <S.ProfileImg>
              <img src={user.profile_image} alt={user.nickname} />
            </S.ProfileImg>
          ) : (
            <S.Login onClick={handleLoginClick}>로그인</S.Login>
          )}
          <S.HamburgerMenu onClick={toggleMenu}>
            {menuOpen ? (
              <>
                <img src={X} alt="닫기" />
              </>
            ) : (
              <>
                <img src={Hamburger} alt="엺기" />
              </>
            )}
          </S.HamburgerMenu>
        </S.MenuWrapper>
      </S.HeaderContainer>
      <S.DropdownMenu open={menuOpen}>
        <S.ItemWrappper>
          <S.DropdownItem
            onClick={() => {
              navigate(`/job`, {
                replace: true,
                state: { scrollToTop: true },
              });
              setMenuOpen(!menuOpen);
            }}
          >
            채용공고
          </S.DropdownItem>
          <S.DropdownItem
            onClick={() => {
              navigate(`/article`, {
                replace: true,
                state: { scrollToTop: true },
              });
              setMenuOpen(!menuOpen);
            }}
          >
            아티클
          </S.DropdownItem>
          <S.DropdownItem
            onClick={() => {
              navigate(`/insight`, {
                replace: true,
                state: { scrollToTop: true },
              });
              setMenuOpen(!menuOpen);
            }}
          >
            인사이트
          </S.DropdownItem>
          <S.DropdownItem
            onClick={() => {
              navigate(`/chat`, {
                replace: true,
                state: { scrollToTop: true },
              });
              setMenuOpen(!menuOpen);
            }}
          >
            커리어챗
          </S.DropdownItem>
          {user && (
            <S.DropdownItem
              onClick={() => {
                logout();
                setMenuOpen(!menuOpen);
              }}
            >
              로그아웃
            </S.DropdownItem>
          )}
        </S.ItemWrappper>
      </S.DropdownMenu>
    </S.HeaderSection>
  );
}

export default Header;
