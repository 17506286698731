import { Helmet } from "react-helmet-async";
import OGIMG from "../../assets/OG_800_400.png";

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
}

export default function SEO({
  title,
  description,
  image,
  url,
  type,
}: MetaProps) {
  const defaultTitle = "따끔 - 간호사 커리어 플랫폼";
  const defaultDescription = "요즘 간호사들의 커리어 플랫폼, 따끔!";
  const defaultogURL = "https://ddakkm.me"; // URL에 프로토콜 추가
  const defaultogTYPE = "website";
  const defaultogIMG = OGIMG;

  const formatImageUrl = (imgUrl: string) => {
    if (imgUrl.startsWith("http")) {
      return imgUrl;
    }
    if (imgUrl.startsWith("//")) {
      return `https:${imgUrl}`;
    }
    return `https://${imgUrl}`; // URL에 프로토콜 추가
  };

  const imageUrl = image ? formatImageUrl(image) : defaultogIMG;

  return (
    <Helmet>
      <title>
        {title ? `${title} - 간호사 커리어 플랫폼, 따끔` : defaultTitle}
      </title>
      <meta name="description" content={description || defaultDescription} />
      <meta
        property="og:title"
        content={title ? `${title} - 간호사 커리어 플랫폼, 따끔` : defaultTitle}
      />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:url" content={url || defaultogURL} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content={type || defaultogTYPE} />
    </Helmet>
  );
}
