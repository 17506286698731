import { useNavigate } from "react-router-dom";
import JobCard from "../../../components/JobCard/JobCard";
import useJobPostingList from "../../../utils/hooks/useJobList";
import * as S from "./Article.style";
import RightArrow from "../../../assets/Right_Arrow.svg";

function Job() {
  const { jobPostings } = useJobPostingList("전체");
  const navigate = useNavigate();

  return (
    <S.Section>
      <S.TitleWrapper>
        <div>
          <S.Title>
            지금 바로 지원할 수 있는 <em>채용공고</em>
          </S.Title>
          <S.Desc>병원부터 임상 외 분야까지 한 번에 볼 수 있어요.</S.Desc>
        </div>
        <S.RightArrow
          onClick={() => {
            navigate(`/job`, { replace: true, state: { scrollToTop: true } });
          }}
        >
          <img src={RightArrow} alt="바로가기" />
        </S.RightArrow>
      </S.TitleWrapper>
      <S.JobContainer>
        <S.JobWrapper>
          {jobPostings.slice(0, 5).map((job) => (
            <JobCard JobContent={job} key={job.jobposting_id} />
          ))}
        </S.JobWrapper>
      </S.JobContainer>
    </S.Section>
  );
}

export default Job;
