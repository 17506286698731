import * as S from "./JobList.style";
import { useEffect, useState } from "react";
import { TabComponent } from "../../../components/Tab/TabComponent";
import useJobPostingList from "../../../utils/hooks/useJobList";
import JobCard from "../../../components/JobCard/JobCard";
import Hospital from "../../../assets/hospital.png";
import { useLocation } from "react-router-dom";
import SEO from "../../../components/Helmet/Helmet";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../../utils/hooks/useAuth";

const categories = [
  "전체",
  "병원",
  "임상/연구",
  "제약/바이오",
  "의료기기",
  "디지털헬스",
  "산업",
  "공공",
  "교육",
  "해외",
  "그외",
];

function JobList() {
  const { user } = useAuth();
  const [selectedCategory, setSelectedCategory] = useState("전체");
  const location = useLocation();
  const { jobPostings, loading } = useJobPostingList(selectedCategory);

  console.log(jobPostings);
  const handleTabChange = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
    mixpanel.track("Pageview_JobList", {
      user: user ? "true" : "false",
    });
  }, [location]);

  return (
    <>
      <SEO />
      <S.Section>
        <S.BannerContainer
          onClick={() => {
            window.open(`https://tally.so/r/mV8VRM`);
            mixpanel.track("Click_Banner_Job_JobList");
          }}
        >
          <S.Banner>
            <S.TitleWrapper>
              <S.BannerTitle>무료로 채용공고를 등록 해보세요!</S.BannerTitle>
              <S.BannerDesc>간호사가 필요하다면? 따끔에서!</S.BannerDesc>
            </S.TitleWrapper>
            <S.BannerImg>
              <img src={Hospital} alt="채용공고 무료 업로드" />
            </S.BannerImg>
          </S.Banner>
        </S.BannerContainer>
        <S.HeadingContainer>
          <S.Heading>채용공고</S.Heading>
          <S.Desc>간호사 면허만 있으면 바로 지원할 수 있어요.</S.Desc>
        </S.HeadingContainer>
        <S.ContentWrapper>
          <TabComponent categories={categories} onTabChange={handleTabChange} />
        </S.ContentWrapper>
        <S.JobContaier>
          <S.JobWrapper>
            {loading
              ? Array.from({ length: 5 }).map((_, idx) => (
                  <S.SkeletonJobCard key={idx}>
                    <S.Thumbnail className="shimmer" />
                    <S.TitleWrapper>
                      <S.Title className="shimmer" />
                      <S.Company className="shimmer" />
                    </S.TitleWrapper>
                  </S.SkeletonJobCard>
                ))
              : jobPostings.map((job, idx) => (
                  <JobCard JobContent={job} key={idx} />
                ))}
          </S.JobWrapper>
        </S.JobContaier>
      </S.Section>
    </>
  );
}

export default JobList;
