// Modal.tsx
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as S from "./Modal.style";

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"; // 스크롤 막기

    return () => {
      document.body.style.overflow = "auto"; // 스크롤 복원
    };
  }, []);

  return ReactDOM.createPortal(
    <S.ModalOverlay onClick={onClose}>
      <S.ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </S.ModalContent>
    </S.ModalOverlay>,
    document.body
  );
};

export default Modal;
