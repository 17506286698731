import { useEffect, useState } from "react";
import { TabComponent } from "../../../components/Tab/TabComponent";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "../../AritclePage/ArticleList/ArticleList.style";
import { useQueryClient } from "react-query";
import ApplyCreator from "../../../assets/ApplyCreator.png";
import Next from "../../../assets/Next.svg";
import useInsightList from "../../../utils/hooks/useInsightList";
import SEO from "../../../components/Helmet/Helmet";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../../utils/hooks/useAuth";

const categories = ["전체", "병원", "트렌드", "커리어", "일상", "학교"];

function InsightPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { insights } = useInsightList();
  const { user } = useAuth();

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
    mixpanel.track("Pageview_InsightList", {
      user: user ? "true" : "false",
    });
  }, [location]);

  return (
    <>
      <SEO />
      <S.Section>
        <S.BannerContainer
          onClick={() => {
            window.open("https://tally.so/r/meEL2Q");
            mixpanel.track("Click_Banner_Insight_InsightList");
          }}
        >
          <S.Banner>
            <S.TitleWrapper>
              <S.BannerDesc>
                나만의 커리어 이야기
                <br />
                인터뷰로 정리해 보세요!
              </S.BannerDesc>
              <S.ApplyCTA>
                <span>인터뷰 신청하기</span>
                <img src={Next} alt="지원하기" />
              </S.ApplyCTA>
            </S.TitleWrapper>
            <S.BannerImg>
              <img src={ApplyCreator} alt="크리에이터 지원" />
            </S.BannerImg>
          </S.Banner>
        </S.BannerContainer>
        <S.HeadingContainer>
          <S.Heading>커리어 인사이트</S.Heading>
          <S.Desc>따끔에서만 만날 수 있는 커리어 인사이트 💉</S.Desc>
        </S.HeadingContainer>
        <S.ContentWrapper>
          {insights.map((insight) => (
            <S.InterviewCard
              key={insight.insight_id}
              onClick={() => {
                navigate(`/insight/${insight.insight_id}`, {
                  state: { insight },
                });
              }}
            >
              <S.InterviewImg>
                <img src={insight.image} alt="인터뷰 이미지" />
              </S.InterviewImg>
              <S.InterviewCategory>
                <span>{insight.category}</span>
              </S.InterviewCategory>
              <S.InterviewTitle>{insight.title}</S.InterviewTitle>
              <S.InterviewPreview>{insight.preview}</S.InterviewPreview>
            </S.InterviewCard>
          ))}
        </S.ContentWrapper>
      </S.Section>
    </>
  );
}

export default InsightPage;
