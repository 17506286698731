import styled from "styled-components";
import { onLarge } from "../../../styles/mediaQueries";

export const Section = styled.section`
  width: 100%;
  .swiper-pagination {
    bottom: 6px; /* 페이지네이션 위치 조정 */
  }

  .swiper-pagination-bullet {
    width: 5px; /* 페이지네이션 점 크기 조절 */
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5); /* 흰색 배경, 투명도 50% */
    border-radius: 50%;
    margin: 0 4px; /* 간격 조절 */
    transition: background-color 0.3s ease, transform 0.3s ease; /* 부드러운 전환 효과 */
  }

  .swiper-pagination-bullet-active {
    background-color: #fff; /* 활성화된 페이지네이션 점 색상 */
  }
`;

export const BannerContainer = styled.div<{ bgcolor: string }>`
  height: 100px;
  padding: 22px 14px;
  background: ${(props) => props.bgcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Banner = styled.div`
  width: 323px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BannerTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const BannerDesc = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
`;

export const BannerImg = styled.div`
  width: 52px;
  height: 52px;
  img {
    width: 100%;
  }
`;
