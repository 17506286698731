import { useEffect } from "react";
import { client } from "../client";
import { useQuery } from "react-query";

export interface Author {
  author_id: string;
  author_name: string;
  author_image: string;
  author_profile: string;
  registered_date: string;
  company: string;
  experience: number;
}

export interface Article {
  article_id: string;
  entry_id: string;
  category: string;
  title: string;
  date: string;
  image: string;
  preview: string;
  content: any;
  keyword: string;
  author: Author;
  attribute: "기본" | "인기";
  likes: string[];
  createdAt: string;
}

const useArticleList = (selectedCategory: string) => {
  const { isFetching, isError, data, error, refetch } = useQuery(
    ["articles", selectedCategory],
    async () => {
      const response = await client.getEntries<any>({
        content_type: "article",
        include: 1,
        "fields.category":
          selectedCategory !== "전체" ? selectedCategory : undefined,
      });

      const articles = response.items.map((item: any) => {
        const { fields, sys } = item;

        return {
          article_id: fields.articleId,
          entry_id: sys.id,
          category: fields.category,
          title: fields.title,
          date: fields.date,
          createdAt: sys.createdAt,
          image: fields.image.fields.file.url,
          preview: fields.content.content[0].content[0].value,
          content: fields.content,
          keyword: fields.keyword,
          likes: fields?.likes || [],
          author: {
            author_id: fields.author.fields.authorId,
            author_name: fields.author.fields.authorName,
            author_image: fields.author.fields.author_image.fields.file.url,
            author_profile: fields.author.fields.authorProfile,
            registered_date: fields.author.fields.registered_date,
            company: fields.author.fields.company,
            experience: fields.author.fields.experience,
          },
        } as Article;
      });

      articles.sort(
        (a: Article, b: Article) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      return articles;
    },
    {
      enabled: false,
      staleTime: 60 * 60 * 1000,
    }
  );

  useEffect(() => {
    if (selectedCategory) {
      refetch();
    }
  }, [selectedCategory, refetch]);

  return {
    articles: data || [],
    loading: isFetching,
    error: isError ? error : null,
    refetch,
  };
};

export default useArticleList;
