import styled, { keyframes } from "styled-components";
import { onLarge } from "../../../styles/mediaQueries";

export const Section = styled.section`
  padding: 60px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;
  max-width: 390px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
`;

export const ArticleCategory = styled.div`
  color: #3984f4;
  font-size: 12px;
  font-weight: 600;
`;

export const ArticleTitle = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 600;
`;

export const ArticleInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AuthorProfile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const AuthorImg = styled.div`
  width: 40px;
  height: 40px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
`;

export const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AuthorName = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 500;
`;

export const AuthorCompany = styled.div`
  padding: 4px;
  border-radius: 4px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  font-weight: 600;
  inline-size: fit-content;
`;

export const ArticleDate = styled.div`
  color: #8f9094;
  font-size: 10px;
`;

export const ContentWrapper = styled.div`
  font-size: 16px;
  line-height: 170%;

  h2 {
    color: #333d4b;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  h3 {
    color: #4e5968;
    font-weight: 600;
    padding-bottom: 4px;
  }
  p {
    color: #4e5968;
    /* padding-top: 8px; */
    padding-bottom: 16px;
  }
  i {
    font-style: normal;
    background-color: #ffff83;
  }

  ul {
    padding: 0 20px;
  }

  ol {
    padding: 0 20px;
    ::marker {
      color: #4e5968;
    }
  }
`;

export const ContentImg = styled.div`
  width: 100%;
  margin-bottom: 30px;
  img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const Preview = styled.div`
  position: relative;
`;

export const Fader = styled.div`
  height: 250px;
  top: 0;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.95) 60%
  );
`;

export const JoinBanner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 400px;
  padding-top: 150px;
`;

export const JoinScript = styled.div`
  font-size: 20px;
  line-height: 140%;
  font-weight: 700;
  text-align: center;
  margin: 20px;
`;

export const BannerContainer = styled.div`
  height: 50px;
  background: linear-gradient(95deg, #96e49a -8.09%, #2658d9 106.46%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
`;

export const Banner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 0 20px;
  max-width: 390px;
`;

export const CreatorCTA = styled.span`
  padding: 6px 10px;
  color: #3984f4;
  font-size: 12px;
  font-weight: 700;
  background-color: white;
  border-radius: 8px;
  line-height: normal;
`;

export const InterviewImg = styled.div`
  width: 100%;
  height: 220px;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InterviewCategory = styled.div`
  color: #3984f4;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  text-align: center;
`;

export const InterviewTitle = styled.div`
  color: #424857;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: Pretendard;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 39px */
  margin-bottom: 12px;
  padding: 0 16px;
  word-break: keep-all;
`;

export const InterviewInfo = styled.div`
  color: #424857;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 36px;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  align-items: center;
`;

export const InterviewAuthorImg = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  img {
    border: 0.961px solid #dfdfdf;
    border-radius: 48px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const InterviewAuthorName = styled.div`
  color: #424857;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%; /* 27px */
  margin-bottom: 6px;
`;

export const InterviewAuthorCareer = styled.div`
  color: #424857;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 27px */
  margin-bottom: 10px;
`;

export const InterviewAuthorProfile = styled.div`
  color: #9195a0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  padding: 0 12px;
  word-break: keep-all;
`;

export const AuthorProfileBtn = styled.button`
  margin-top: 36px;
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  span {
    font-size: 15px;
    padding: 10px 14px;
    color: #424857;
    font-weight: 600;
  }
`;

export const ShareWrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Share = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  background: transparent;
  color: #4e5968;
  padding: 10px 14px;
  border-radius: 8px;
  font-size: 15px;
  border: 1px #ededef solid;

  font-weight: 600;

  img {
    width: 17px;
    height: 17px;
  }
`;

export const Recommend = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 100px;
  width: 100%;
  /* padding: 0 20px; */
`;

export const RecommendTitle = styled.div`
  font-size: 20px;
  color: #4e5968;
  font-weight: 600;
  margin-bottom: 24px;
`;

export const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: first baseline;
  gap: 20px;
`;

export const ArticleCard = styled.div`
  width: 159px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 6px;
`;

export const CardImg = styled.div`
  width: 159px;
  height: 106px;
  margin-bottom: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const CardCategory = styled.div`
  font-size: 12px;
  color: #3984f4;
  font-weight: 500;
  /* margin-bottom: 6px; */
`;

export const CardTitle = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 600;
  width: 159px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const Author = styled.div`
  color: #8f9094;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 6px;
`;

export const Company = styled.div`
  color: #8f9094;
  font-size: 11px;
  font-weight: 500;
  em {
    font-size: 9px;
  }
`;

export const NoteImg = styled.div`
  width: 390px;
  height: 220px;
  margin-bottom: 16px;
  /* padding: 0 20px; */

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const NoteTitle = styled.div`
  color: #424857;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: Pretendard;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 39px */
  margin-bottom: 30px;
  padding: 0 16px;
  word-break: keep-all;
`;

export const Divider = styled.div`
  padding: 30px 0;
`;

export const NotePreview = styled.div`
  position: relative;
  height: auto;
`;

export const NoteFader = styled.div`
  height: 120px;
  top: 0;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.95) 100%
  );
`;

export const NoteJoinBanner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  top: 120px;
  /* margin-top: 120px; */
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export const NoteWrapper = styled.div`
  font-size: 16px;
  line-height: 170%;
  position: relative;

  h2 {
    color: #333d4b;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  h3 {
    color: #4e5968;
    font-weight: 600;
    padding-bottom: 4px;
  }
  p {
    color: #4e5968;
    /* padding-top: 8px; */
    padding-bottom: 16px;
  }
  i {
    font-style: normal;
    background-color: #ffff83;
  }

  ul {
    padding: 0 20px;
  }

  ol {
    padding: 0 20px;
    ::marker {
      color: #4e5968;
    }
  }
`;
