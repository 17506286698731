import { useState, useEffect } from "react";
import { client } from "../client";
import { useQuery } from "react-query";


export interface Chat {
  chatId: number;
  entry_id: string;
  image?: string;
  title: string;
  status: string;
  description: string;
  apply: string[];
  createdAt: string;
}

const useChatList = () => {
  const { isFetching, isError, data, error, refetch } = useQuery(
    ["chats"],
    async () => {
      const query: any = {
        content_type: "chat",
        include: 1,
      };

      const response = await client.getEntries<any>(query);
      const chats = response.items.map((item: any) => {
        const { fields, sys } = item;
        return {
          chatId: fields.chatId,
          entry_id: sys.id,
          title: fields.title,
          image: fields.image?.fields?.file?.url,
          status: fields.status,
          description: fields.description,
          apply: fields?.apply || [],
          createdAt: sys.createdAt,
        } as Chat;
      });

      chats.sort(
        (a: Chat, b: Chat) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      return chats;
    },
    {
      enabled: false, // Fetch only when selectedCategory changes
      staleTime: 60 * 60 * 1000, // Cache for 1 hour
    }
  );

  useEffect(() => {
    refetch();    
  }, [refetch]);

  return {
    chats: data || [],
    loading: isFetching,
    error: isError ? error : null,
    refetch,
  };
};

export default useChatList;
