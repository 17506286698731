import * as S from "./Banner.style";
import syringe from "../../../assets/syringe.png";
import write from "../../../assets/write.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import mixpanel from "mixpanel-browser";

const BANNER = [
  {
    color: "linear-gradient(95deg, #96E49A -8.09%, #2658D9 106.46%)",
    title: "따끔 크리에이터에 지원해 보세요!",
    desc: "따끔 크리에이터 혜택이 궁금하다면?",
    img: syringe,
    url: "https://tally.so/r/3N00PG",
  },
  {
    color: "#3984F4",
    title: "간호사 자기소개서 30분 만에 끝!",
    desc: "현직 간호사들이 만든 간호사 취업코칭 AI, 널패스",
    img: write,
    url: "https://www.nurpass.me",
  },
];

function BannerSection() {
  return (
    <S.Section>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        {BANNER.map((banner, idx) => (
          <SwiperSlide key={idx}>
            <S.BannerContainer
              bgcolor={banner.color}
              key={idx}
              onClick={() => {
                if (banner.url) {
                  window.open(banner.url);
                  mixpanel.track("Click_Banner_Main",{
                    banner: banner.title
                  });
                }
              }}
            >
              <S.Banner>
                <S.TitleWrapper>
                  <S.BannerTitle>{banner.title}</S.BannerTitle>
                  <S.BannerDesc>{banner.desc}</S.BannerDesc>
                </S.TitleWrapper>
                <S.BannerImg>
                  <img src={banner.img} alt="배너이미지" />
                </S.BannerImg>
              </S.Banner>
            </S.BannerContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Section>
  );
}

export default BannerSection;
