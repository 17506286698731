// Modal.style.ts
import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 0 20px;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 24px;
  max-width: 390px;
  width: 100%;
  z-index: 1001;
`;
