import { useLocation, useNavigate, useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as S from "../../AritclePage/ArticleContent//ArticleContent.style";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../utils/hooks/useAuth";
import { KakaoLoginButton } from "../../../views/LoginPage/components/LoginButton";
import useInsightList, { Insight } from "../../../utils/hooks/useInsightList";
import SEO from "../../../components/Helmet/Helmet";
import ShareIcon from "../../../assets/ShareIcon.svg";
import mixpanel from "mixpanel-browser";
import toast, { Toaster } from "react-hot-toast";
import { managementClient } from "../../../utils/managementClient";
import LikeButton from "../../../components/LikesButton/LikeButton";
import useArticleList from "../../../utils/hooks/useAriticleList";
import { Article } from "../../../utils/hooks/useTopArticles";
import { Helmet } from "react-helmet-async";

const InsightContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { insightId } = useParams();
  const insightFromList = location.state?.insight || null;
  const { user } = useAuth();
  const { insights } = useInsightList();
  const { articles } = useArticleList("전체");
  const [insight, setInsight] = useState<Insight | null>(
    insightFromList || null
  );
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!insight && insights.length > 0) {
      const foundInsight = insights.find((a) => a.insight_id == insightId);
      if (foundInsight) {
        setInsight(foundInsight);
        setLikeCount(foundInsight.likes.length);
        if (user) {
          const userId = user.id.toString();
          setIsLiked(foundInsight.likes.includes(userId));
        }
      }
    } else if (insight) {
      setLikeCount(insight.likes.length);
      if (user) {
        const userId = user.id.toString();
        setIsLiked(insight.likes.includes(userId));
      }
    }

    mixpanel.track("Contentview_Insight", {
      contentId: insightId,
      contentTitle: insight?.title,
      user: user ? "true" : "false",
      category: insight?.category,
    });
  }, [insight, insights, insightId, user]);

  const handleShare = async () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      try {
        await navigator.share({
          title: insight?.title,
          text: insight?.title,
          url: `https://ddakkm.me/insight/${insight?.insight_id}`,
        });
        mixpanel.track("Click_Share_Insight", {
          user: user ? "true" : "false",
          contentId: insight?.insight_id,
          contentTitle: insight?.title,
        });
        // console.log("Content shared successfully");
      } catch (error) {
        // console.error("Error sharing content:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(
          `https://ddakkm.me/insight/${insight?.insight_id}`
        );
        toast.success("링크가 복사됐어요!");
        mixpanel.track("Click_Share_Insight", {
          user: user ? "true" : "false",
          contentId: insight?.insight_id,
          contentTitle: insight?.title,
        });
      } catch (error) {
        toast.error("링크 복사에 실패했습니다.");
        // console.error("Error copying to clipboard:", error);
      }
    }
  };

  const updateInsightLikes = async (
    insightId: string,
    userId: string,
    isLiked: boolean
  ): Promise<string[]> => {
    try {
      const space = await managementClient.getSpace(
        process.env.REACT_APP_CONTENTFUL_SPACE!
      );
      const environment = await space.getEnvironment("master");

      // 최신 버전의 엔트리를 가져옵니다
      const insightEntry = await environment.getEntry(insightId);

      let updatedLikes = insightEntry.fields.likes?.["en-US"] || [];
      if (!Array.isArray(updatedLikes)) {
        updatedLikes = [];
      }

      if (isLiked) {
        updatedLikes = updatedLikes.filter((id: string) => id !== userId);
      } else {
        updatedLikes.push(userId);
      }

      // 엔트리를 업데이트합니다. 이때 sys.version을 함께 전송합니다.
      insightEntry.fields.likes = { "en-US": updatedLikes };
      const updatedEntry = await insightEntry.update();
      mixpanel.track("Click_Like_Button", {
        category: "insight",
        title: insight?.title,
        id: insight?.insight_id,
      });

      // 업데이트된 엔트리를 게시합니다
      await updatedEntry.publish();

      return updatedLikes;
    } catch (error: any) {
      console.error("Error updating likes:", error);
      if (error.sys && error.sys.id === "VersionMismatch") {
        console.error("Version mismatch occurred. Retrying...");
        // 버전 불일치 에러 발생 시 재시도
        return updateInsightLikes(insightId, userId, isLiked);
      }
      throw error;
    }
  };

  const handleLikeToggle = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault(); // 기본 동작 막기
    event.stopPropagation();
    if (!user) {
      navigate("/signin");
      return;
    }
    const userId = user.id.toString();

    // 로컬 상태를 먼저 업데이트합니다.
    const updatedIsLiked = !isLiked;
    const updatedLikeCount = isLiked ? likeCount - 1 : likeCount + 1;

    setIsLiked(updatedIsLiked);
    setLikeCount(updatedLikeCount);

    // Contentful에 업데이트 요청을 보냅니다.
    try {
      await updateInsightLikes(insight?.entry_id!, userId, isLiked);
      // const updatedLikes = await updateArticleLikes(
      //   article?.entry_id!,
      //   userId,
      //   isLiked
      // );

      // setArticle((prev) => ({
      //   ...prev!,
      //   likes: updatedLikes,
      // }));
    } catch (error) {
      console.error("Error updating likes:", error);
      toast.error("좋아요를 업데이트하는 중에 문제가 발생했습니다.");

      // 요청 실패 시 로컬 상태를 원래대로 되돌립니다.
      setIsLiked(isLiked);
      setLikeCount(likeCount);
    }
  };

  const shuffledArticles = useMemo(() => {
    return [...articles].sort(() => Math.random() - 0.5);
  }, [articles, insightId]); // articleId를 의존성 배열에 추가

  const handleArticleClick = (randomArticle: Article) => {
    navigate(`/article/${randomArticle.article_id}`, {
      state: { article: randomArticle },
      replace: true,
    });
  };

  return (
    <>
      {insight && (
        <>
          <Helmet>
            <title>{insight.title}</title>
            <meta property="og:title" content={insight.title} />
            <meta property="og:description" content={insight.preview} />
            <meta property="og:image" content={`https://${insight.image}`} />
            <meta property="og:url" content={window.location.href} />
          </Helmet>

          {/* <SEO
            title={insight?.title}
            description={insight.preview}
            image={insight.image}
            url={`https://ddakkm.me/article/${insight.insight_id}`}
            type={"article"}
          /> */}
          <S.Section>
            <S.InterviewImg>
              <img src={insight.image} alt="인터뷰 이미지" />
            </S.InterviewImg>
            <S.Container>
              <S.InterviewCategory>{insight.category}</S.InterviewCategory>
              <S.InterviewTitle>{insight.title}</S.InterviewTitle>
              <S.InterviewInfo>
                {insight.author.author_name} 선생님
              </S.InterviewInfo>
              <S.ContentWrapper>
                {user ? (
                  <>
                    <>{documentToReactComponents(insight.content)}</>

                    <S.AuthorWrapper>
                      <S.InterviewAuthorImg>
                        <img
                          src={insight.author.author_image}
                          alt="크리에이터 이미지"
                        />
                      </S.InterviewAuthorImg>
                      <S.InterviewAuthorName>
                        {insight.author.author_name}
                      </S.InterviewAuthorName>
                      <S.InterviewAuthorCareer>
                        {insight.author.company} · {insight.author.experience}
                      </S.InterviewAuthorCareer>
                      <S.InterviewAuthorProfile>
                        {insight.author.author_profile}
                      </S.InterviewAuthorProfile>
                      <S.AuthorProfileBtn
                        onClick={() => {
                          navigate(`/author/${insight.author.author_id}`);
                        }}
                      >
                        <span>선생님 글 더보기</span>
                      </S.AuthorProfileBtn>
                    </S.AuthorWrapper>
                  </>
                ) : (
                  <S.Preview>
                    <S.Fader />
                    {insight.preview}
                    <S.JoinBanner>
                      <S.JoinScript>
                        지금 로그인하고
                        <br />
                        커리어 인사이트를 쌓아보세요!
                      </S.JoinScript>
                      <KakaoLoginButton />
                    </S.JoinBanner>
                  </S.Preview>
                )}
              </S.ContentWrapper>
              <S.ShareWrapper>
                <LikeButton
                  isLiked={isLiked}
                  likeCount={likeCount}
                  onLikeToggle={handleLikeToggle}
                />
                <S.Share onClick={handleShare}>
                  <img src={ShareIcon} alt="공유하기" />
                  <span>공유하기</span>
                </S.Share>
              </S.ShareWrapper>
              <S.Recommend>
                <S.RecommendTitle>함께보면 좋은 아티클</S.RecommendTitle>
                <S.ArticleWrapper>
                  {shuffledArticles.slice(0, 4).map((randomArticle) => (
                    <S.ArticleCard
                      key={randomArticle.article_id}
                      onClick={() => handleArticleClick(randomArticle)}
                    >
                      <S.CardImg>
                        <img src={randomArticle.image} alt="아티클 썸네일" />
                      </S.CardImg>
                      <S.CardCategory>{randomArticle.category}</S.CardCategory>
                      <S.CardTitle>{randomArticle.title}</S.CardTitle>
                      <S.Author>
                        {randomArticle.author.author_name} 선생님
                      </S.Author>
                    </S.ArticleCard>
                  ))}
                </S.ArticleWrapper>
              </S.Recommend>
            </S.Container>
          </S.Section>
        </>
      )}
      <Toaster />
    </>
  );
};

export default InsightContent;
