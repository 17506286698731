import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as S from "./ArticleContent.style";
import useArticleList, { Article } from "../../../utils/hooks/useAriticleList";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/hooks/useAuth";
import { KakaoLoginButton } from "../../../views/LoginPage/components/LoginButton";
import SEO from "../../../components/Helmet/Helmet";
import ShareIcon from "../../../assets/ShareIcon.svg";
import mixpanel from "mixpanel-browser";
import toast, { Toaster } from "react-hot-toast";
import { managementClient } from "../../../utils/managementClient";
import LikeButton from "../../../components/LikesButton/LikeButton";

const ArticleContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { articleId } = useParams();
  const articleFromList = location.state?.article || null;
  const { user } = useAuth();
  const { articles } = useArticleList("전체");
  const [article, setArticle] = useState<Article | null>(
    articleFromList || null
  );
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!article && articles.length > 0) {
      const foundArticle = articles.find((a) => a.article_id == articleId);
      if (foundArticle) {
        setArticle(foundArticle);
        setLikeCount(foundArticle.likes.length);
        if (user) {
          const userId = user.id.toString();
          setIsLiked(foundArticle.likes.includes(userId));
        }
      }
    } else if (article) {
      setLikeCount(article.likes.length);
      if (user) {
        const userId = user.id.toString();
        setIsLiked(article.likes.includes(userId));
      }
    }

    mixpanel.track("Contentview_Article", {
      user: user ? "true" : "false",
      contentId: articleId,
      contentTitle: article?.title,
      category: article?.category,
    });
  }, [article, articles, articleId, user]);

  // 날짜 포맷 변경 함수
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const shuffledArticles = useMemo(() => {
    return [...articles].sort(() => Math.random() - 0.5);
  }, [articles, articleId]); // articleId를 의존성 배열에 추가

  const handleShare = async () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      try {
        await navigator.share({
          title: article?.title,
          text: article?.title,
          url: `https://ddakkm.me/article/${article?.article_id}`,
        });
        mixpanel.track("Click_Share_article", {
          user: user ? "true" : "false",
          contentId: article?.article_id,
          contentTitle: article?.title,
        });
        // console.log("Content shared successfully");
      } catch (error) {
        // console.error("Error sharing content:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(
          `https://ddakkm.me/article/${article?.article_id}`
        );
        toast.success("링크가 복사됐어요!");
        mixpanel.track("Click_Share_article", {
          user: user ? "true" : "false",
          contentId: article?.article_id,
          contentTitle: article?.title,
        });
      } catch (error) {
        toast.error("링크 복사에 실패했어요.");
      }
    }
  };

  const handleArticleClick = (randomArticle: Article) => {
    setArticle(randomArticle);
    navigate(`/article/${randomArticle.article_id}`, {
      state: { article: randomArticle },
      replace: true,
    });
  };

  const updateArticleLikes = async (
    articleId: string,
    userId: string,
    isLiked: boolean
  ): Promise<string[]> => {
    try {
      const space = await managementClient.getSpace(
        process.env.REACT_APP_CONTENTFUL_SPACE!
      );
      const environment = await space.getEnvironment("master");
      const articleEntry = await environment.getEntry(articleId);

      let updatedLikes = articleEntry.fields.likes?.["en-US"] || [];
      if (!Array.isArray(updatedLikes)) {
        updatedLikes = [];
      }

      if (isLiked) {
        updatedLikes = updatedLikes.filter((id: string) => id !== userId);
      } else {
        updatedLikes.push(userId);
      }

      articleEntry.fields.likes = { "en-US": updatedLikes };
      const updatedEntry = await articleEntry.update();

      await updatedEntry.publish();
      mixpanel.track("Click_Like_Button", {
        category: "article",
        title: article?.title,
        id: article?.article_id,
      });

      return updatedLikes;
    } catch (error: any) {
      console.error("Error updating likes:", error);
      if (error.sys && error.sys.id === "VersionMismatch") {
        console.error("Version mismatch occurred. Retrying...");

        return updateArticleLikes(articleId, userId, isLiked);
      }
      throw error;
    }
  };

  const handleLikeToggle = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault(); // 기본 동작 막기
    event.stopPropagation();
    if (!user) {
      navigate("/signin");
      return;
    }
    const userId = user.id.toString();

    // 로컬 상태를 먼저 업데이트합니다.
    const updatedIsLiked = !isLiked;
    const updatedLikeCount = isLiked ? likeCount - 1 : likeCount + 1;

    setIsLiked(updatedIsLiked);
    setLikeCount(updatedLikeCount);

    // Contentful에 업데이트 요청을 보냅니다.
    try {
      await updateArticleLikes(article?.entry_id!, userId, isLiked);
      // const updatedLikes = await updateArticleLikes(
      //   article?.entry_id!,
      //   userId,
      //   isLiked
      // );

      // setArticle((prev) => ({
      //   ...prev!,
      //   likes: updatedLikes,
      // }));
    } catch (error) {
      console.error("Error updating likes:", error);
      toast.error("좋아요를 업데이트하는 중에 문제가 발생했습니다.");

      // 요청 실패 시 로컬 상태를 원래대로 되돌립니다.
      setIsLiked(isLiked);
      setLikeCount(likeCount);
    }
  };

  return (
    <>
      {article && (
        <>
          <SEO
            title={article?.title}
            description={article.preview}
            image={article.image}
            url={`https://ddakkm.me/article/${article.article_id}`}
          />
          <S.Section>
            <S.BannerContainer
              onClick={() => {
                window.open("https://tally.so/r/3N00PG");
                mixpanel.track("Click_Banner_Article_ArticleList");
              }}
            >
              <S.Banner>
                <span>나만의 인사이트, 따끔에 올려보세요!</span>
                <S.CreatorCTA>작가 혜택보기✍️</S.CreatorCTA>
              </S.Banner>
            </S.BannerContainer>
            <S.Container>
              <S.TitleWrapper>
                <S.ArticleCategory>{article.category}</S.ArticleCategory>
                <S.ArticleTitle>{article.title}</S.ArticleTitle>
                <S.ArticleInfo>
                  <S.AuthorProfile>
                    <S.AuthorImg>
                      <img
                        src={article.author.author_image}
                        alt="크리에이터 이미지"
                      />
                    </S.AuthorImg>
                    <S.AuthorInfo>
                      <S.AuthorName>{article.author.author_name}</S.AuthorName>
                      <S.AuthorCompany>
                        <span>
                          {article.author.company}
                          {" ・ "}
                          {article.author.experience}
                        </span>
                      </S.AuthorCompany>
                    </S.AuthorInfo>
                  </S.AuthorProfile>
                  <S.ArticleDate>{formatDate(article.date)}</S.ArticleDate>
                </S.ArticleInfo>
              </S.TitleWrapper>
              <S.ContentWrapper>
                <S.ContentImg>
                  <img src={article.image} alt="아티클 썸네일" />
                </S.ContentImg>
                {user ? (
                  <>
                    <>{documentToReactComponents(article.content)}</>
                    <S.AuthorWrapper>
                      <S.InterviewAuthorImg>
                        <img
                          src={article.author.author_image}
                          alt="크리에이터 이미지"
                        />
                      </S.InterviewAuthorImg>
                      <S.InterviewAuthorName>
                        {article.author.author_name}
                      </S.InterviewAuthorName>
                      <S.InterviewAuthorCareer>
                        {article.author.company} · {article.author.experience}
                      </S.InterviewAuthorCareer>
                      <S.InterviewAuthorProfile>
                        {article.author.author_profile}
                      </S.InterviewAuthorProfile>
                      <S.AuthorProfileBtn
                        onClick={() => {
                          navigate(`/author/${article.author.author_id}`);
                        }}
                      >
                        <span>선생님 글 더보기</span>
                      </S.AuthorProfileBtn>
                    </S.AuthorWrapper>
                  </>
                ) : (
                  <S.Preview>
                    <S.Fader />
                    {article.preview}
                    <S.JoinBanner>
                      <S.JoinScript>
                        지금 로그인하고
                        <br />
                        커리어 인사이트를 쌓아보세요!
                      </S.JoinScript>
                      <KakaoLoginButton />
                    </S.JoinBanner>
                  </S.Preview>
                )}
              </S.ContentWrapper>
              <S.ShareWrapper>
                <LikeButton
                  isLiked={isLiked}
                  likeCount={likeCount}
                  onLikeToggle={handleLikeToggle}
                />
                <S.Share onClick={handleShare}>
                  <img src={ShareIcon} alt="공유하기" />
                  <span>공유하기</span>
                </S.Share>
              </S.ShareWrapper>
              <S.Recommend>
                <S.RecommendTitle>함께보면 좋은 아티클</S.RecommendTitle>
                <S.ArticleWrapper>
                  {shuffledArticles.slice(0, 4).map((randomArticle) => (
                    <S.ArticleCard
                      key={randomArticle.article_id}
                      onClick={() => handleArticleClick(randomArticle)}
                    >
                      <S.CardImg>
                        <img src={randomArticle.image} alt="아티클 썸네일" />
                      </S.CardImg>
                      <S.CardCategory>{randomArticle.category}</S.CardCategory>
                      <S.CardTitle>{randomArticle.title}</S.CardTitle>
                      <S.Author>
                        {randomArticle.author.author_name} 선생님
                      </S.Author>
                    </S.ArticleCard>
                  ))}
                </S.ArticleWrapper>
              </S.Recommend>
            </S.Container>
          </S.Section>
        </>
      )}
      <Toaster />
    </>
  );
};

export default ArticleContent;
