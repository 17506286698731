import * as S from "./Article.style";
import { useNavigate } from "react-router-dom";
import useArticleList from "../../../utils/hooks/useAriticleList";
import RightArrow from "../../../assets/Right_Arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import mixpanel from "mixpanel-browser";

function TrendSection(){

  const { articles } = useArticleList("트렌드");
  const navigate = useNavigate();

  return(
    <S.Section>
      <S.TitleWrapper>
        <div>
          <S.Title>
            따끔에서 알려주는 <em>간호 트렌드</em>
          </S.Title>
          <S.Desc>지금 간호계는 어떤 일이 있을까요?</S.Desc>
        </div>
        <S.RightArrow
          onClick={() => {
            navigate(`/article`, { replace: true, state: { scrollToTop: true } });
          }}
        >
          <img src={RightArrow} alt="바로가기" />
        </S.RightArrow>
      </S.TitleWrapper>
        <S.TrendContainer>
          <Swiper
            modules={[Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true, el: '.swiper-pagination' }}
            speed={300}
          
        >
          {articles &&
            articles.slice(0, 4).map((article) => (
              <SwiperSlide key={article.article_id}>
              <S.TrendCard
                key={article.article_id}
                onClick={() => {
                  navigate(`/article/${article.article_id}`, {
                    state: { article },
                  });
                  mixpanel.track("Click_Main_Trend",{
                    title: article.title,
                    id: article.article_id,
                  });
                }}
              >
                <S.TrendCardImg>
                  <img src={article.image} alt="이미지"/>
                </S.TrendCardImg>
                <S.TrendCardWrapper>
                  <S.TrendCardTitle>
                    {article.title}
                  </S.TrendCardTitle>
                  <S.TrendCardDesc>
                    {article.preview}
                  </S.TrendCardDesc>
                </S.TrendCardWrapper>
              </S.TrendCard>
              </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div> {/* 인디케이터를 위한 div 추가 */}
            </Swiper>
        </S.TrendContainer>
    </S.Section>
  )
}

export default TrendSection