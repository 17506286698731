import { useNavigate } from "react-router-dom";
import * as S from "./Article.style";
import RightArrow from "../../../assets/Right_Arrow.svg";
import useArticleList from "../../../utils/hooks/useAriticleList";

function Article() {
  const { articles } = useArticleList("전체");
  const navigate = useNavigate();

  return (
    <S.ArticleSeciton>
      <S.TitleWrapper>
        <div>
          <S.Title>
            이번 주 가장 인기 있는 <em>아티클</em>
          </S.Title>
          <S.Desc>요즘 간호사들의 트렌드와 정보를 알아보세요.</S.Desc>
        </div>
        <S.RightArrow
          onClick={() => {
            navigate(`/article`, {
              replace: true,
              state: { scrollToTop: true },
            });
          }}
        >
          <img src={RightArrow} alt="바로가기" />
        </S.RightArrow>
      </S.TitleWrapper>
      <S.ArticleCardWrapper>
        {articles &&
          articles.slice(0, 10).map((article) => (
            <S.ArticleCard
              key={article.article_id}
              onClick={() => {
                navigate(`/article/${article.article_id}`, {
                  state: { article },
                });
              }}
            >
              <S.CardDesc>
                <S.CardCategory>{article.category}</S.CardCategory>
                <S.CardTitle>{article.title}</S.CardTitle>
                <S.Author>{article.author.author_name} 선생님</S.Author>
                <S.Company>
                  {article.author.company} <em>|</em>{" "}
                  {article.author.experience}
                </S.Company>
              </S.CardDesc>
              <S.CardImg>
                <img src={article.image} alt="아티클 썸네일" />
              </S.CardImg>
            </S.ArticleCard>
          ))}
      </S.ArticleCardWrapper>
    </S.ArticleSeciton>
  );
}

export default Article;
