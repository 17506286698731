import styled from "styled-components";
import kakaoLogo from "../../../assets/logo_kakao.png";
import mixpanel from "mixpanel-browser";

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 52px;
  background-color: #fee500;
  border: none;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    background-color: #ffd700;
  }
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

declare global {
  interface Window {
    referrer: string;
  }
}

export const KakaoLoginButton = () => {
  const loginWithKakao = () => {
    const client_id = process.env.REACT_APP_KAKAO_CLIENT_ID;
    const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
    // const redirect_uri = `http://localhost:3000/auth/kakao/callback`;

    const currentPath = window.location.pathname;
    const referrerPath = window?.referrer ?? "";
    let returnUrl = "/"; // 기본 리다이렉트 URL: 홈("/")
    mixpanel.track("Click_LoginButton", {
      currentPath,
      referrerPath,
    });

    if (
      currentPath.startsWith("/article/") ||
      currentPath.startsWith("/insight/") ||
      currentPath.startsWith("/note/") ||
      currentPath.startsWith("/chat/") ||
      currentPath.startsWith("/job/")
    ) {
      returnUrl = currentPath;
    }

    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&state=${encodeURIComponent(
      returnUrl
    )}`;
  };

  return (
    <LoginButton onClick={loginWithKakao}>
      <Logo src={kakaoLogo} alt="kakao" />
      카카오로 시작하기
    </LoginButton>
  );
};
