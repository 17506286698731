// src/hooks/useAuth.ts
import { useAtom } from "jotai";
import { userAtom, tokensAtom } from "../../jotai/authAtoms";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface Tokens {
  access_token: string | null;
  refresh_token: string | null;
}

export interface User {
  id: number;
  nickname: string;
  profile_image: string;
  email: string;
  name: string;
  gender: string;
  birthday?: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

export function useAuth() {
  const [user, setUser] = useAtom(userAtom);
  const [tokens, setTokens] = useAtom(tokensAtom);
  const navigate = useNavigate();

  const login = (userData: User, tokenData: Tokens) => {
    setUser(userData);
    setTokens(tokenData);
    localStorage.setItem("access_token", tokenData.access_token as string);
    localStorage.setItem("refresh_token", tokenData.refresh_token as string);
  };

  const logout = () => {
    setUser(null);
    setTokens({ access_token: null, refresh_token: null });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/");
  };

  const checkUser = async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      try {
        const response = await axios.get<User>(
          `https://api.ddakkm.me/auth/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Failed to fetch user:", error);
        logout();
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await axios.get<User>(
            `${process.env.REACT_APP_API_URL}/auth/user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUser(response.data);
        } catch (error) {
          console.error("Failed to fetch user:", error);
          logout();
        }
      }
    };

    fetchUser();
  }, [setUser]);

  return {
    user,
    tokens,
    login,
    logout,
    checkUser,
  };
}
