import styled, { keyframes } from "styled-components";
import React, { useState } from "react";

export const Card = styled.div`
  border-radius: 6px;
  background: #fff;
  border: 1px solid #ededef;
  /* box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08); */
  width: 169px;
  cursor: pointer;
`;

export const Thumbnail = styled.div`
  width: 167px;
  height: 116px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    /* border-radius: 6px; */
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px 8px;
`;

export const Title = styled.div`
  width: 142px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  line-height: 1.2;
`;

export const Company = styled.div`
  color: #8f9094;
  font-size: 12px;
  font-weight: 600;
`;
