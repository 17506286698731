import styled, { keyframes } from "styled-components";

export const Section = styled.section`
  padding: 100px 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #eef2ff 0%, rgba(238, 245, 255, 0) 100%);
`;

export const TitleContainer = styled.div`
  height: 32px;
  margin-bottom: 4px;
  overflow: hidden;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const scrollAnimation = keyframes`
  0%, 20% {
    transform: translateY(0);
  }
  25%, 45% {
    transform: translateY(-25%);
  }
  50%, 70% {
    transform: translateY(-50%);
  }
  75%, 95% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const TitleAnimation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  animation: ${scrollAnimation} 8s ease-in-out infinite;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
`;

export const Heading = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #3984f4;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  img {
    margin-left: 4px;
    width: 14px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const CardTitle = styled.div`
  color: #424857;
  font-size: 14px;
  font-weight: 500;
`;

export const CardImg = styled.div`
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
`;
