import styled, { keyframes } from "styled-components";
import React, { useState } from "react";

const TabSection = styled.section`
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: baseline;
  margin-bottom: 40px;
  flex-wrap: wrap;
`;

const SelectedTab = styled.div`
  border-radius: 12px;
  background: #212124;
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
`;

const Tab = styled.div`
  border-radius: 12px;
  background: #f2f3f6;
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  color: #868b94;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

interface TabComponentProps {
  categories: string[];
  onTabChange: (category: string) => void;
}

export const TabComponent = ({
  categories,
  onTabChange,
}: TabComponentProps) => {
  const [selectedTab, setSelectedTab] = useState(categories[0]);

  const handleTabClick = (category: string) => {
    setSelectedTab(category);
    onTabChange(category);
  };

  return (
    <TabSection>
      {categories.map((category, index) => (
        <div key={index} onClick={() => handleTabClick(category)}>
          {selectedTab === category ? (
            <SelectedTab>{category}</SelectedTab>
          ) : (
            <Tab>{category}</Tab>
          )}
        </div>
      ))}
    </TabSection>
  );
};
