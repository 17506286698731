import { useState, useEffect } from "react";
import { client } from "../client";
import { useQuery } from "react-query";

export interface JobPosting {
  jobposting_id: number;
  category: string;
  title: string;
  image: {
    url: string;
    title: string;
    description: string;
  };
  location: string;
  experience: string;
  deadline: string;
  deadline_date: boolean;
  content: {
    nodeType: string;
    content: Array<{
      nodeType: string;
      value: string;
    }>;
  };
  keyword: string[];
  link: string;
  keywords: string[];
  company: {
    fields: {
      id: number;
      category: string;
      class: string;
      name: string;
      image: {};
    };
  };
}

export interface Job {
  jobposting_id: number;
  entry_id: string;
  title: string;
  image?: string;
  location: string;
  experience: string;
  deadline: string;
  deadline_date: boolean;
  content: string;
  keyword: string[];
  link: string;
  category: string;
  company_name: string;
  attribute: string;
  createdAt: string;
  keywords: string[];
  company: {
    category: string | "";
    class: string | "";
    id: number | "";
    image: string | "";
    name: string | "";
  };
}

const useJobPostingList = (selectedCategory: string) => {
  const { isFetching, isError, data, error, refetch } = useQuery(
    ["jobPostings", selectedCategory],
    async () => {
      const query: any = {
        content_type: "jobposting",
        include: 1,
      };

      if (selectedCategory !== "전체") {
        query["fields.category"] = selectedCategory;
      }

      const response = await client.getEntries<any>(query);
      console.log(response);
      const Jobs = response.items.map((item: any) => {
        const { fields, sys } = item;
        return {
          jobposting_id: fields.jobpostingId,
          entry_id: sys.id,
          title: fields.title,
          image: fields.image?.fields?.file?.url,
          location: fields.location,
          experience: fields.experience,
          deadline: fields.deadline,
          content: fields.content?.content[0]?.content[0]?.value || "",
          keyword: fields.keyword,
          link: fields.link,
          category: fields.category,
          company_name: fields.company_name,
          attribute: fields.attribute,
          deadline_date: fields.deadlineDate,
          createdAt: sys.createdAt,
          keywords: fields.keywords,
          company: {
            category: fields?.company?.fields?.category || "",
            class: fields?.company?.fields?.class || "",
            id: fields?.company?.fields?.id || "",
            image: fields?.company?.fields?.image?.fields?.file?.url || "",
            name: fields?.company?.fields?.name || "",
          },
        } as Job;
      });

      Jobs.sort(
        (a: Job, b: Job) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      return Jobs;
    },
    {
      enabled: false, // Fetch only when selectedCategory changes
      staleTime: 60 * 60 * 1000, // Cache for 1 hour
    }
  );

  useEffect(() => {
    if (selectedCategory) {
      refetch();
    }
  }, [selectedCategory, refetch]);

  return {
    jobPostings: data || [],
    loading: isFetching,
    error: isError ? error : null,
    refetch,
  };
};

export default useJobPostingList;
