import styled, { keyframes } from "styled-components";

export const Section = styled.section`
  border-radius: 24px;
  position: relative;
`;

export const X = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 16px;
  right: 16px;
  img {
  }
`;

export const JobImage = styled.div`
  height: 242px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    object-fit: cover;
  }
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  padding: 0 20px 24px 20px;
`;

export const Status = styled.div`
  color: #3984f4;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 140%;
`;

export const Content = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 20px;
`;

export const CoffeChat = styled.div`
  color: #8f9094;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 16px;
  line-height: 150%;
  em {
    font-weight: 700;
    line-height: 180%;
  }
`;

export const Apply = styled.div`
  color: #3984f4;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

export const Send = styled.div<{ isLiked: boolean }>`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${(props) => (props.isLiked ? "#A6A6A6" : "#3984f4")};
  cursor: pointer;

  span {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const Link = styled.div`
  border-radius: 4px;
  background-color: #ededed;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  width: 100%;
  height: 36px;
  cursor: pointer;
  font-weight: 500;
`;

export const JoinModal = styled.div`
  background-color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
`;

export const JoinScript = styled.div`
  font-size: 20px;
  line-height: 140%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonBase = styled.div`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
`;

export const SkeletonImage = styled(SkeletonBase)`
  height: 242px;
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  margin-bottom: 20px;
`;

export const SkeletonTitle = styled(SkeletonBase)`
  height: 24px;
  width: 80%;
  margin-bottom: 12px;
`;

export const SkeletonContent = styled(SkeletonBase)`
  height: 14px;
  width: 100%;
  margin-bottom: 8px;
`;

export const SkeletonApply = styled(SkeletonBase)`
  height: 12px;
  width: 40%;
  margin-bottom: 24px;
`;

export const SkeletonButton = styled(SkeletonBase)`
  height: 48px;
  width: 100%;
  border-radius: 4px;
`;
