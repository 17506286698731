import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import MainPage from "./views/MainPage";
import Footer from "./components/Footer";
import ArticleList from "./views/AritclePage/ArticleList";
import JobList from "./views/JobPage/JobList";
import ArticleContent from "./views/AritclePage/ArticleContent";
import LoginPage from "./views/LoginPage";
import KakaoRedirectHandler from "./components/KakaoRedirectHandler";
import AuthProvider from "./components/AuthProvider";
import InsightPage from "./views/InsightPage/InsightList";
import InsightContent from "./views/InsightPage/InsightContent";
import { HelmetProvider } from "react-helmet-async";
import NotePage from "./views/NotePage";
import Creator from "./views/CreatorPage";
import ChatList from "./views/ChatPage/ChatList";
import NewJobContent from "./views/JobPage/NewJobContent";

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/article" element={<ArticleList />} />
            <Route path="/article/:articleId" element={<ArticleContent />} />
            <Route path="/insight" element={<InsightPage />} />
            <Route path="/insight/:insightId" element={<InsightContent />} />
            <Route path="/job" element={<JobList />} />
            <Route path="/job/:jobId" element={<NewJobContent />} />
            <Route path="/note/:noteId" element={<NotePage />} />
            <Route
              path="/auth/kakao/callback"
              element={<KakaoRedirectHandler />}
            />
            <Route path="/author/:authorId" element={<Creator />} />
            <Route path="/chat/*" element={<ChatList />} />
          </Routes>
        </Router>
        <Footer />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
