import { useEffect, useState } from "react";
import { TabComponent } from "../../../components/Tab/TabComponent";
import * as S from "./ArticleList.style";
import useArticleList from "../../../utils/hooks/useAriticleList";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import ApplyCreator from "../../../assets/ApplyCreator.png";
import Next from "../../../assets/Next.svg";
import SEO from "../../../components/Helmet/Helmet";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../../utils/hooks/useAuth";

const categories = ["전체", "병원", "트렌드", "커리어", "일상", "학교"];

function ArticleList() {
  const [selectedCategory, setSelectedCategory] = useState("전체");
  const { articles } = useArticleList(selectedCategory);
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleTabChange = (category: string) => {
    setSelectedCategory(category);
    queryClient.invalidateQueries(["articles", category]);
  };

  useEffect(() => {
    if (location.state && location.state.scrollToTop) {
      window.scrollTo(0, 0);
    }
    mixpanel.track("Pageview_ArticleList", {
      user: user ? "true" : "false",
    });
  }, [location]);

  return (
    <>
      <SEO />

      <S.Section>
        <S.BannerContainer
          onClick={() => {
            window.open("https://tally.so/r/3N00PG");
            mixpanel.track("Click_Banner_Article_ArticleList");
          }}
        >
          <S.Banner>
            <S.TitleWrapper>
              <S.BannerDesc>
                내 글이 있다면,
                <br />
                따끔에 올려보세요!
              </S.BannerDesc>
              <S.ApplyCTA>
                <span>크리에이터 혜택 보러가기</span>
                <img src={Next} alt="지원하기" />
              </S.ApplyCTA>
            </S.TitleWrapper>
            <S.BannerImg>
              <img src={ApplyCreator} alt="크리에이터 지원" />
            </S.BannerImg>
          </S.Banner>
        </S.BannerContainer>
        <S.HeadingContainer>
          <S.Heading>아티클</S.Heading>
          <S.Desc>따끔에서만 만날 수 있는 커리어 인사이트 💉</S.Desc>
        </S.HeadingContainer>
        <S.ContentWrapper>
          <TabComponent categories={categories} onTabChange={handleTabChange} />
          {articles.length > 0
            ? articles.map((article) => (
                <S.ContentCard
                  key={article.article_id}
                  onClick={() => {
                    navigate(`/article/${article.article_id}`, {
                      state: { article },
                    });
                  }}
                >
                  <S.ListCardWrapper>
                    <S.Category>{article.category}</S.Category>
                    <S.Title>{article.title}</S.Title>
                    <S.Content>{article.preview}</S.Content>
                    <S.AuthorWrapper>
                      <S.AuthorImg>
                        <img
                          src={article.author.author_image}
                          alt="작가 사진"
                        />
                      </S.AuthorImg>
                      <S.AuthorInfo>
                        <S.AuthorName>
                          {article.author.author_name}
                        </S.AuthorName>
                        <S.AuthorCompany>
                          <span>
                            {article.author.company}
                            {" ・ "}
                            {article.author.experience}
                          </span>
                        </S.AuthorCompany>
                      </S.AuthorInfo>
                    </S.AuthorWrapper>
                  </S.ListCardWrapper>
                  <S.ListCardImg>
                    <img src={article.image} alt="카드 썸네일" />
                  </S.ListCardImg>
                </S.ContentCard>
              ))
            : Array.from({ length: 5 }).map((_, index) => (
                <S.SkeletonCard key={index}>
                  <S.Category className="shimmer" />
                  <S.Title className="shimmer" />
                  <S.Content className="shimmer" />
                  <S.AuthorWrapper>
                    <S.AuthorImg className="shimmer" />
                    <S.AuthorInfo>
                      <S.AuthorName className="shimmer" />
                      <S.AuthorCompany className="shimmer" />
                    </S.AuthorInfo>
                  </S.AuthorWrapper>
                </S.SkeletonCard>
              ))}
        </S.ContentWrapper>
      </S.Section>
    </>
  );
}

export default ArticleList;
